import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { db, storage } from '../../../../firebase/FirebaseConfig';
import { collection, addDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'
import '../../../global/QuillCustomStyles.css';
import './AddBlogForm.css';

const AddBlogForm = () => {
  const [blogData, setBlogData] = useState({
    image: null,
    title: '',
    slug: '',
    annotation: '',
    content: '',
    date: ''
  });
  const [preview, setPreview] = useState({ image: null });
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const quillModules = {
    toolbar: {
      container: [
        [{ 'header': [1, 2, 3, false] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{'list': 'ordered'}, {'list': 'bullet'}],
        [{ 'script': 'sub'}, { 'script': 'super' }],
        [{ 'indent': '-1'}, { 'indent': '+1' }],
        [{ 'direction': 'rtl' }],
        [{ 'color': ['#f21170', '#72147e', '#35183c', '#323232', 'white'] }, { 'background': ['#f21170', '#72147e', '#35183c', '#323232', 'white'] }],
        [{ 'align': [] }],
        ['link', 'image', 'video', 'blockquote'],
        ['clean']
      ],
    }
  }; 

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === 'image') {
      if (files && files[0]) {
        setBlogData(prev => ({ ...prev, [name]: files[0] }));
        setPreview(prev => ({ ...prev, [name]: URL.createObjectURL(files[0]) }));
      }
    } else if ( name === 'content') {
      //THIS will be handled by Quill
    } else {
      setBlogData(prev => ({ ...prev, [name]: value }));
    }
  };

  const handleContentChange = (content) => {
    setBlogData(prev => ({ ...prev, content }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    try {
      let imageUrl = null;
      if (blogData.image) {
        const imageRef = ref(storage, `blog_images/${Date.now()}_${blogData.image.name}`);
        await uploadBytes(imageRef, blogData.image);
        imageUrl = await getDownloadURL(imageRef);
      }

      const blogRef = await addDoc(collection(db, 'blogs'), {
        title: blogData.title,
        slug: blogData.slug,
        annotation: blogData.annotation,
        content: blogData.content,
        date: blogData.date,
        image: imageUrl,
        createdAt: new Date()
      });

      navigate('/admin/blog-list');
    } catch (error) {
      console.error("DEBUG: ---> Error adding blog post: ", error);
      console.error("DEBUG! ---> Error details:", error.code, error.message);
      setError('Ошибка! Не удалось загрузить блог статью');
    } finally {
      setLoading(false);
    }
  };

  const handleImageClick = (inputId) => {
    document.getElementById(inputId).click();
  };

  return (
    <div className="add-blog-form-container">
      <h2>Добавить новый блог</h2>
      {error && <div className="error-message">{error}</div>}
      <form onSubmit={handleSubmit} className="add-blog-form">
        <div className="form-group">
          <label>Изображение блога</label>
          <div
            className="image-upload-area"
            onClick={() => handleImageClick('image')}
          >
            {preview.image ? (
              <img src={preview.image} alt="Blog preview" className="image-preview" />
            ) : (
              <div className="abf-upload-placeholder">
                <span>Нажмите, чтобы загрузить изображение</span>
              </div>
            )}
          </div>
          <input
            type="file"
            id="image"
            name="image"
            onChange={handleChange}
            accept="image/*"
            style={{ display: 'none' }}
          />
        </div>

        <div className="form-group">
          <label htmlFor="title">Заголовок</label>
          <input
            type="text"
            id="title"
            name="title"
            value={blogData.title}
            onChange={handleChange}
            placeholder="Введите заголовок блога"
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="slug">Транслитерация URL</label>
          <input
            type="text"
            id="slug"
            name="slug"
            value={blogData.slug}
            onChange={handleChange}
            placeholder="nazvanie-zagolovka-bloga"
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="annotation">Аннотация</label>
          <textarea
            id="annotation"
            name="annotation"
            value={blogData.annotation}
            onChange={handleChange}
            rows="3"
            placeholder="Краткое описание блога"
            required
          ></textarea>
        </div>

        <div className="form-group">
          <label htmlFor="content">Текст блога</label>
          <ReactQuill
            value={blogData.content}
            onChange={handleContentChange}
            modules={quillModules}
            theme="snow"
          />
        </div>

        <div className="form-group">
          <label htmlFor="date">Дата публикации</label>
          <input
            type="date"
            id="date"
            name="date"
            value={blogData.date}
            onChange={handleChange}
            required
          />
        </div>

        <button type="submit" className="submit-button" disabled={loading}>
          {loading ? 'Сохранение...' : 'Сохранить'}
        </button>
      </form>
    </div>
  );
};

export default AddBlogForm;