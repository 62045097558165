import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { db, storage } from '../../../../firebase/FirebaseConfig';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import '../../../global/QuillCustomStyles.css';
import './EditBlogForm.css';

const EditBlogForm = () => {
    const { blogId } = useParams();
    const navigate = useNavigate();
    const [blogData, setBlogData] = useState({
        image: null,
        title: '',
        slug: '',
        annotation: '',
        content: '',
        date: ''
    });
    const [preview, setPreview] = useState({ image: null });
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true);

const quillModules = {
  toolbar: {
    container: [
      [{ 'header': [1, 2, 3, false] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{'list': 'ordered'}, {'list': 'bullet'}],
      [{ 'script': 'sub'}, { 'script': 'super' }],
      [{ 'indent': '-1'}, { 'indent': '+1' }],
      [{ 'direction': 'rtl' }],
      [{ 'color': ['#f21170', '#72147e', '#35183c', '#323232', 'white'] }, { 'background': ['#f21170', '#72147e', '#35183c', '#323232', 'white'] }],
      [{ 'align': [] }],
      ['link', 'image', 'video', 'blockquote'],
      ['clean']
    ],
  }
};

useEffect(() => {
    const fetchBlogDataFromDB = async () => {
        try {
            const blogDocRef = doc(db, 'blogs', blogId);
            const blogDocSnapshot = await getDoc(blogDocRef);
            if (blogDocSnapshot.exists()) {
                const data = blogDocSnapshot.data();
                setBlogData(data);
                setPreview({ image: data.image });
            } else {
                setError('Блог статья не найдена');
            }
        } catch (error) {
            console.error("DEBUG: ---> Error fetching blog:", error);
            setError('Ошибка! Невозможно загрузить блог статью');
        } finally {
            setLoading(false);
        }
    };

    fetchBlogDataFromDB();
}, [blogId]);

const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === 'image') {
        if (files && files[0]) {
            setBlogData(prev => ({ ...prev, [name]: files[0] }));
            setPreview(prev => ({ ...prev, [name]: URL.createObjectURL(files[0]) }));
        }
    } else if (name === 'content') {
      // this will be handeled by Quill
    } else {
        setBlogData(prev => ({ ...prev, [name]: value }));
    }
};

const handleContentChange = (content) => {
  setBlogData(prev => ({ ...prev, content }));
};

const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    try {
        let imageUrl = blogData.image;
        if (blogData.image instanceof File) {
            const imageRef = ref(storage, `blog_images/${blogId}`);
            await uploadBytes(imageRef, blogData.image);
            imageUrl = await getDownloadURL(imageRef);
        }

        const blogDocRef = doc(db, 'blogs', blogId);
        await updateDoc(blogDocRef, {
            ...blogData,
            image: imageUrl
        });

        navigate('/admin/blog-list');
    } catch (error) {
        console.error("DEBUG: ---> Error updating blog:", error);
        setError('Ошибка! Невозможно обновить блог статью');
    } finally {
        setLoading(false);
    }
};

if (loading) return <div className="ebf-loading">Загружаю...</div>;
if (error) return <div className="ebf-error">{error}</div>

    return (
        <div className="edit-blog-form-container">
      <h2>Редактировать блог статью</h2>
      {error && <div className="error-message">{error}</div>}
      <form onSubmit={handleSubmit} className="add-blog-form">
        <div className="form-group">
          <label>Изображение блога</label>
          <div
            className="image-upload-area"
            onClick={() => document.getElementById('image').click()}
          >
            {preview.image ? (
              <img src={preview.image} alt="Blog preview" className="image-preview" />
            ) : (
              <div className="ebf-upload-placeholder">
                <span>Нажмите, чтобы загрузить изображение</span>
              </div>
            )}
          </div>
          <input
            type="file"
            id="image"
            name="image"
            onChange={handleChange}
            accept="image/*"
            style={{ display: 'none' }}
          />
        </div>

        <div className="form-group">
          <label htmlFor="title">Заголовок</label>
          <input
            type="text"
            id="title"
            name="title"
            value={blogData.title}
            onChange={handleChange}
            placeholder="Введите заголовок блога"
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="slug">Транслитерация URL</label>
          <input
            type="text"
            id="slug"
            name="slug"
            value={blogData.slug}
            onChange={handleChange}
            placeholder="nazvanie-zagolovka-bloga"
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="annotation">Аннотация</label>
          <textarea
            id="annotation"
            name="annotation"
            value={blogData.annotation}
            onChange={handleChange}
            rows="3"
            placeholder="Краткое описание блога"
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="content">Текст блога</label>
          <ReactQuill
            value={blogData.content}
            onChange={handleContentChange}
            modules={quillModules}
            theme="snow"
          />
        </div>

        <div className="form-group">
          <label htmlFor="date">Дата публикации</label>
          <input
            type="date"
            id="date"
            name="date"
            value={blogData.date}
            onChange={handleChange}
            required
          />
        </div>

        <button type="submit" className="submit-button" disabled={loading}>
          {loading ? 'Сохранение...' : 'Сохранить'}
        </button>
      </form>
    </div>
    );
};

export default EditBlogForm;