import React, { useEffect } from 'react';
import { useNavigate, Outlet, useLocation } from 'react-router-dom';
import { FaAddressCard, FaBlog, FaBook, FaExpeditedssl, FaFileSignature, FaSignOutAlt } from 'react-icons/fa';
import { useAuth } from '../../../hooks/useAuth';
import './AdminPanel.css';

const AdminPanel = () => {
    const  { user, loading, logout } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (!loading && !user) {
            navigate('/admin/login');
        }
    }, [user, loading, navigate]);

    const handleLogout = async () => {
        try {
            await logout();
            navigate('/admin/login');
        } catch (error) {
            console.error("DEBUG! ---> Logout error:", error);
        }
    };
 
    const icons = [
        { icon: FaAddressCard, label: "Activity Providers", path: "/admin/activity-providers" },
        { icon: FaBlog, label: "Blog", path: "/admin/blog-list" },
        { icon: FaBook, label: "Fairytales", path: "/admin/fairytales-list" },
        { icon: FaFileSignature, label: "Terms", path: "/admin/terms-of-service/A8XyDqEUgUfqTw3mmpvs" },
        { icon: FaExpeditedssl, label: "Privacy", path: "/admin/privacy-policy/uLBYmFAbMuDgXwLgW2SV" },
    ];

    const handleIconClick = (path) => {
        navigate(path);
    };

    const isActive = (path) => {
        return location.pathname.startsWith(path);
    }

    if (loading) {
        return <div>Загружаю...</div>;
    }

    if (!user) {
        return null; //THIS prevents (or should prevent) rendering of child routes if the user is not authenticated
    }

    return (
        <div className="admin-panel">
            <div className="sidebar">
                <div className="profile-icon">
                    <img src='/dd_logo/ДляДетей.ру.png' alt='' />
                </div>
                <div className="middle-icons">
                    {icons.map((IconItem, index) => (
                        <div
                            key={index}
                            className={`icon-wrapper ${isActive(IconItem.path) ? 'active' : ''}`}
                            onClick={() => handleIconClick(IconItem.path)}
                        >
                            <IconItem.icon className="icon" />
                        </div>
                    ))}
                </div>
                <div className="logout-icon">
                    <FaSignOutAlt className="icon" onClick={handleLogout} />
                </div>
            </div>

            <div className="main-content-container">
                <div className="main-content">
                    <Outlet />
                </div>
            </div>
        </div>
    );
};

export default AdminPanel;