import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { OnboardingContext } from '../../../../context/OnboardingContext';
import { db, auth } from '../../../../firebase/FirebaseConfig';
import { doc, collection, serverTimestamp, writeBatch } from 'firebase/firestore';
import Stepper from '../common/Stepper';
import { categoryDisplayNames } from '../../../../data/categoryDisplayNames';

import './OnboardingStepTwo.css';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { fetchSignInMethodsForEmail } from 'firebase/auth';

const checkIfEmailInUse = async (email) => {
    try {
        const signInMethods = await fetchSignInMethodsForEmail(auth, email);
        return signInMethods.length > 0;
    } catch (error) {
        console.error("DEBUG: --> Error checking email:", error);
        return false;
    }
};

const OnboardingStepTwo = () => {
    const { onboardingData, setOnboardingData } = useContext(OnboardingContext);
    const navigate = useNavigate();
    const { firstName } = onboardingData;
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [businessName, setBusinessName] = useState(onboardingData.businessName || '');
    const [inn, setInn] = useState(onboardingData.inn || '');
    const [category, setCategory] = useState(onboardingData.category || '');
    const [mainCity, setMainCity] = useState(onboardingData.mainCity || '');

    const steps = [
        { title: 'Первый шаг пройден' },
        { title: 'Основная информация' },
        // { title: 'Тариф' },
    ];

    useEffect(() => {
        // Check if the User came from the Step 1
        const savedData = localStorage.getItem('onboardingData');
        if (!savedData || !JSON.parse(savedData).tempData) {
            navigate('/activity-provider/onboarding/step1');
        }
    }, [navigate]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setLoading(true);
        
        // NEW: Enhanced validation checks
        if (!businessName.trim() || !validateInn(inn) || !category || !mainCity) {
          setError('Пожалуйста, заполните все поля корректно');
          setLoading(false);
          return;
        }
      
        try {
          const { email, password } = onboardingData;
          
          // NEW: Sequential error handling
          if (!email || !password) {
            setLoading(false);
            navigate('/activity-provider/onboarding/step1');
            return;
          }
      
          // NEW: Structured transaction-like approach
          const emailInUse = await checkIfEmailInUse(email);
          if (emailInUse) {
            setError('Этот email уже зарегистрирован');
            setLoading(false);
            return;
          }
      
          // NEW: Create user first
          const userCredential = await createUserWithEmailAndPassword(auth, email, password);
          const user = userCredential.user;
      
          // NEW: Batch write for atomic operations
          const batch = writeBatch(db);
      
          // NEW: Create activity document
          const activityDocRef = doc(collection(db, 'activities'));
          const activityData = {
            isActive: false,
            isNew: true,
            isPromoted: false,
            businessName: businessName.trim(),
            inn,
            category,
            mainCity,
            providerId: user.uid,
            activityId: activityDocRef.id,
            joinedDate: serverTimestamp(),
            updatedAt: serverTimestamp(),
            isComplete: false
          };
          batch.set(activityDocRef, activityData);
      
          // NEW: Create provider document
          const providerDocRef = doc(db, 'activityProviders', user.uid);
          const providerData = {
            firstName: onboardingData.firstName,
            email,
            businessName,
            inn,
            category,
            mainCity,
            activityId: activityDocRef.id,
            joinedDate: serverTimestamp()
          };
          batch.set(providerDocRef, providerData);
      
          // NEW: Commit batch
          await batch.commit();
      
          // NEW: Update context only after successful batch commit
          setOnboardingData({
            ...providerData,
            uid: user.uid,
            tempData: false
          });
      
          // Clear temporary data
          localStorage.removeItem('onboardingData');
          
          // Navigate to success
          navigate('/activity-provider/onboarding/success');
          
        } catch (error) {
          console.error("Registration error:", error);
          // NEW: More specific error messages
          if (error.code === 'auth/email-already-in-use') {
            setError('Этот email уже используется');
          } else if (error.code === 'auth/invalid-email') {
            setError('Неверный формат email');
          } else if (error.code === 'auth/weak-password') {
            setError('Слишком простой пароль');
          } else {
            setError('Ошибка при создании аккаунта. Попробуйте позже');
          }
        }
      };

    const validateInn = (inn) => {
        let updatedInn = inn.replace(/\D/g, '');
        if (updatedInn.length > 12) {
            updatedInn = updatedInn.slice(0, 12);
        }
        return updatedInn.length === 10 || updatedInn.length === 11 || updatedInn.length === 12;    
    };

    return (
        <div className="onboarding-step-two">
            <h2>{`${firstName}, теперь давайте добавим основную информацию о Вашем бизнесе.`}</h2>
            <div className="onboarding-container">
                <Stepper steps={steps} currentStep={1} />
                <div className="ost-form-container">
                    <form onSubmit={handleSubmit}>
                        <div className="ost-form-group">
                            <label htmlFor="businessName">Название</label>
                                <input
                                    type="text"
                                    id="businessName"
                                    placeholder="Например: Детская Балетная Студия Copellia"
                                    value={businessName}
                                    onChange={(e) => setBusinessName(e.target.value)}
                                    required
                                />
                        </div>
                        <div className="ost-form-group">
                            <label htmlFor="inn">ИНН</label>
                                <input
                                    type="text"
                                    id="inn"
                                    name="inn"
                                    placeholder="452167833781"
                                    value={inn}
                                    onChange={(e) => setInn(e.target.value)}
                                    pattern="[0-9]{10}|[0-9]{11}|[0-9]{12}"
                                    required
                                />
                        </div>
                    
                        <div className="ost-form-group">
                            <label htmlFor="category">Категория</label>
                                <select
                                    id="category"
                                    value={category}
                                    onChange={(e) => setCategory(e.target.value)}
                                    required
                                >
                                    <option value="">Выберите категорию</option>
                                    {Object.entries(categoryDisplayNames).map(([key, value]) => (
                                        <option key={key} value={key}>{value}</option>
                                    ))}
                                </select>
                        </div>
                        <div className="ost-form-group">
                            <label htmlFor="mainCity">Основной город</label>
                                <select
                                    id="mainCity"
                                    value={mainCity}
                                    onChange={(e) => setMainCity(e.target.value)}
                                    required
                                >
                                    <option value="">Выберите город</option>
                                    <option value="Новосибирск">Новосибирск</option>
                                    {/* <option value="Красноярск">Красноярск</option> */}
                                    <option value="Бердск">Бердск</option>
                                    <option value="Академгородок">Академгородок</option>
                                    <option value="Искитим">Искитим</option>
                                    {/* <option value="Краснообск">Краснообск</option> */}
                                    {/* <option value="Кольцово">Кольцово</option> */}
                                </select>
                        </div>                    
                        {error && <div className="ost-error-message">{error}</div>}
                        <button type="submit" className="ost-next-button" disabled={loading}>
                            {loading ? 'Создаю аккаунт...': 'Далее' }
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default OnboardingStepTwo;