import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { db } from '../../../../firebase/FirebaseConfig';
import { collection, addDoc, doc, getDoc, updateDoc } from 'firebase/firestore';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'
import '../../../global/QuillCustomStyles.css';
import './AddPrivacyForm.css';

const AddPrivacyForm = () => {
  const { privacyId } = useParams();
  console.log("DEBUG! ---> privacyId:", privacyId);
  const [privacyData, setPrivacyData] = useState({
    title: '',
    content: '',
    lastUpdated: ''
  });
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    console.log("DEBUG! ---> use effect triggered...")
    if (privacyId) {
      setLoading(true);
      const fetchPrivacyDataFromDB = async () => {
        try {
          console.log("DEBUG: ---> fetching existing privacy policy....", privacyId);
          const privacyDocRef = doc(db, 'privacy', privacyId);
          const privacyDocSnapshot = await getDoc(privacyDocRef);
          console.log("SUCCESS! ---> privacy policy fetched successfully: ", privacyId);
          if (privacyDocSnapshot.exists()) {
            setPrivacyData(privacyDocSnapshot.data());
          } else {
            setError('Политика конфиденциальности не найдена');
          }
        } catch (error) {
          setError('Ошибка! Невозможно загрузить Политику конфиденциальности');
        } finally {
          setLoading(false);
        }
      };
      fetchPrivacyDataFromDB();
    }
  }, [privacyId]);

  // Quill modules configuration (same as AddTermsForm)
  const quillModules = {
    toolbar: {
      container: [
        [{ 'header': [1, 2, 3, false] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{'list': 'ordered'}, {'list': 'bullet'}],
        [{ 'script': 'sub'}, { 'script': 'super' }],
        [{ 'indent': '-1'}, { 'indent': '+1' }],
        [{ 'direction': 'rtl' }],
        [{ 'color': ['#f21170', '#72147e', '#35183c', '#323232', 'white'] }, { 'background': ['#f21170', '#72147e', '#35183c', '#323232', 'white'] }],
        [{ 'align': [] }],
        ['link', 'blockquote'],
        ['clean']
      ],
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPrivacyData(prev => ({ ...prev, [name]: value }));
  };

  const handleContentChange = (content) => {
    setPrivacyData(prev => ({ ...prev, content }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);
    console.log('Privacy data submitted to Firebase DB:', privacyData);
    try {
      if (privacyId) {
        // Update existing privacy policy
        const privacyDocRef = doc(db, 'privacy', privacyId);
        await updateDoc(privacyDocRef, {
          title: privacyData.title,
          content: privacyData.content,
          lastUpdated: privacyData.lastUpdated
        });
        console.log('DEBUG: ---> Privacy policy updated with ID: ', privacyId);
      } else {
        // Add new privacy policy
        const privacyRef = await addDoc(collection(db, 'privacy'), {
          title: privacyData.title,
          content: privacyData.content,
          lastUpdated: privacyData.lastUpdated,
          createdAt: new Date()
        });
        console.log('DEBUG: ---> Privacy policy added with ID: ', privacyRef.id);
      }
      navigate('/admin/activity-providers');
    } catch (error) {
      console.error("DEBUG: ---> Error saving privacy policy: ", error);
      console.error("DEBUG! ---> Error details:", error.code, error.message);
      setError('Ошибка! Не удалось сохранить политику конфиденциальности');
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <div className="loading">Загружаю...</div>;
  if (error) return <div className="error">{error}</div>;

  return (
    <div className="add-privacy-form-container">
      <h2>{privacyId ? 'Редактировать Политику конфиденциальности' : 'Добавить Политику конфиденциальности'}</h2>
      {error && <div className="privacy-error-message">{error}</div>}
      <form onSubmit={handleSubmit} className="add-privacy-form">
        <div className="form-group">
          <label htmlFor="title">Заголовок</label>
          <input
            type="text"
            id="title"
            name="title"
            value={privacyData.title}
            onChange={handleChange}
            placeholder="Введите заголовок политики конфиденциальности"
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="content">Текст Политики конфиденциальности</label>
          <ReactQuill
            value={privacyData.content}
            onChange={handleContentChange}
            modules={quillModules}
            theme="snow"
          />
        </div>
        <div className="form-group">
          <label htmlFor="lastUpdated">Дата последнего обновления</label>
          <input
            type="date"
            id="lastUpdated"
            name="lastUpdated"
            value={privacyData.lastUpdated}
            onChange={handleChange}
            required
          />
        </div>
        <button type="submit" className="submit-button" disabled={loading}>
          {loading ? 'Сохранение...' : 'Сохранить'}
        </button>
      </form>
    </div>
  );
};

export default AddPrivacyForm;