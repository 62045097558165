import React from 'react';
import './ActivityCardTestimonialsModal.css';

const ActivityCardTestimonialsModal = ({ activity, onClose }) => {
  return (
    <div className="testimonials-modal" onClick={onClose}>
      <div className="testimonials-modal-content" onClick={(e) => e.stopPropagation()}>
        <button className="close-button" onClick={onClose}>×</button>
        <h2>Отзывы родителей ({activity.parentTestimonials.length})</h2>
        <div className="testimonials-list">
          {activity.parentTestimonials.map((testimonial, index) => (
            <div key={index} className="testimonial-item">
              <h3>{testimonial.parentName}</h3>
              <p>{testimonial.review}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ActivityCardTestimonialsModal;