import React from 'react';
import './ParentTestimonials.css';

const ParentTestimonials = ({ testimonials, currentIndex }) => {
  return (
    <div className="testimonials">
      <div className="testimonials-container">
        <div className="testimonial">
          <span className="testimonial-quote-mark">‟</span>
          <p>{testimonials[currentIndex].review}</p>
          <p className="parent-name">{testimonials[currentIndex].parentName}</p>
        </div>
      </div>
    </div>
  );
};

export default ParentTestimonials;