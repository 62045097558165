import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { db, storage } from '../../../../firebase/FirebaseConfig';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import '../../../global/QuillCustomStyles.css';
import './EditFairytaleForm.css';

const EditFairytaleForm = () => {
    const { fairytaleId } = useParams();
    const [fairytaleData, setFairytaleData] = useState({
        image: null,
        title: '',
        slug: '',
        annotation: '',
        content: '',
        date: ''
    });
    const [preview, setPreview] = useState({ image: null });
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const quillModules = {
        toolbar: {
          container: [
            [{ 'header': [1, 2, 3, false] }],
            ['bold', 'italic', 'underline', 'strike'],
            [{'list': 'ordered'}, {'list': 'bullet'}],
            [{ 'script': 'sub'}, { 'script': 'super' }],
            [{ 'indent': '-1'}, { 'indent': '+1' }],
            [{ 'direction': 'rtl' }],
            [{ 'color': ['#f21170', '#72147e', '#35183c', '#323232', 'white'] }, { 'background': ['#f21170', '#72147e', '#35183c', '#323232', 'white'] }],
            [{ 'align': [] }],
            ['link', 'image', 'video', 'blockquote'],
            ['clean']
          ],
        }
      };

    useEffect(() => {
        const fetchFairytaleDataFromDB = async () => {
            try {
                const fairytaleDocRef = doc(db, 'fairytales', fairytaleId);
                const fairytaleDocSnapshot = await getDoc(fairytaleDocRef);
                if (fairytaleDocSnapshot.exists()) {
                    const data = fairytaleDocSnapshot.data();
                    setFairytaleData(data);
                    setPreview({ image: data.image });
                } else {
                    setError('Сказка не найдена');
                }
            } catch (error) {
                console.error("DEBUG: ---> Error fetching fairytale:", error);
                setError('Ошибка! Невозможн загрузить сказку');
            } finally {
                setLoading(false);
            }
        };

        fetchFairytaleDataFromDB();
    }, [fairytaleId]);

    const handleChange = (e) => {
        const { name, value, files } = e.target;
        if (name === 'image') {
            if (files && files[0]) {
                setFairytaleData(prev => ({ ...prev, [name]: files[0] }));
                setPreview(prev => ({ ...prev, [name]: URL.createObjectURL(files[0]) }));
            }
        } else if (name === 'content') {
            // this will be handeled by Quill
        } else {
            setFairytaleData(prev => ({ ...prev, [name]: value }));
        }
    };

    const handleContentChange = (content) => {
        setFairytaleData(prev => ({ ...prev, content }));
      };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setLoading(true);
    try {
        let imageUrl = fairytaleData.image;
         if (fairytaleData.image instanceof File) {
            const imageRef = ref(storage, `fairytale_images/${fairytaleId}`);
            await uploadBytes(imageRef, fairytaleData.image);
            imageUrl = await getDownloadURL(imageRef);
        }
        const fairytaleDocRef = doc(db, 'fairytales', fairytaleId);
        await updateDoc(fairytaleDocRef, {
            ...fairytaleData,
            image: imageUrl
        });

        navigate('/admin/fairytales-list')
    } catch (error) {
        console.error("DEBUG: ---> Error updating fairytale:", error);
        setError('Ошибка! Невозможно обновить сказку');
    } finally {
        setLoading(false);
    }
};

if (loading) return <div className="eff-loading">Загружаю...</div>
if (error) return <div className="eff-error">{error}</div>

return (
    <div className="edit-fairytale-form-container">
            <h2>Добавить новую сказку</h2>
            {error && <div className="error-message">{error}</div>}
            <form onSubmit={handleSubmit} className="edit-fairytale-form">
                <div className="form-group">
                    <label htmlFor="image">Изображение сказки</label>
                    <div 
                        className="eff-image-upload-area"
                        onClick={() => document.getElementById('image').click()}
                    >
                        {preview.image ? (
                            <img src={preview.image} alt="Fairytale preview" className="eff-image-preview" />
                        ) : (
                            <div className="eff-upload-placeholder">
                                <span>Нажмите, чтобы загрузить изображение</span>
                            </div>
                        )}
                    </div>
                    <input
                        type="file"
                        id="image"
                        name="image"
                        onChange={handleChange}
                        accept="image/*"
                        style={{ display: 'none' }}
                    />
                </div>

                <div className="form-group">
                    <label htmlFor="title">Заголовок</label>
                        <input
                            type="text"
                            id="title"
                            name="title"
                            value={fairytaleData.title}
                            onChange={handleChange}
                            placeholder="Введите заголовок сказки"                               
                            required
                        />
                </div>

                <div className="form-group">
                    <label htmlFor="slug">Транслитерация URL</label>
                        <input
                            type="text"
                            id="slug"
                            name="slug"
                            value={fairytaleData.slug}
                            onChange={handleChange}
                            placeholder="nazvanie-zagolovka-skazki"
                            required
                        />
                 </div>

                <div className="form-group">
                    <label htmlFor="annotation">Аннотация</label>
                        <textarea
                            id="annotation"
                            name="annotation"
                            value={fairytaleData.annotation}
                            onChange={handleChange}
                            rows="3"
                            placeholder="Краткое описание сказки"
                            required
                        />
                </div>

                <div className="form-group">
                    <label htmlFor="content">Текст Сказки</label>
                        <ReactQuill
                            value={fairytaleData.content}
                            onChange={handleContentChange}
                            modules={quillModules}
                            theme="snow"
                />
                </div>

                <div className="form-group">
                    <label htmlFor="date">Дата публикации</label>
                    <input
                        type="date"
                        id="date"
                        name="date"
                        value={fairytaleData.date}
                        onChange={handleChange}
                        required
                    />
                </div>

                <button type="submit" className="submit-button" disabled={loading}>
                    {loading ? 'Сохранение' : 'Сохранить'}
                </button>
            </form>
        </div>
    );
};

export default EditFairytaleForm;