export const categoryDisplayNames = {
    martialArts: "Единоборства",
    football: "Футбол",
    hockey: "Хоккей",
    basketball: "Баскетбол",
    winterSports: "Зимний Спорт",
    extremeSports: "Экстрим",
    voleyball: "Волейбол",
    gymnastics: "Гимнастика",
    atheltics: "Атлетика",
    pedagogy: "ОГЭ, ЕГЭ",
    music: "Музыка",
    dance: "Танцы",
    skills: "Навыки",
    waterSports: "Водный Спорт",
    masterClass: "Мастер-Классы",
    intelligence: "Развитие Интеллекта",
    arts: "Искусство",
    languages: "Школа Языков",
    childCenter: "Детский Центр",
    speechTherapist: "Логопед"
}