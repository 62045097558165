import React, { useState, useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { collection, query, orderBy, getDocs } from "firebase/firestore";
import { db } from "../../../firebase/FirebaseConfig";
import Header from "../../global/Header/Header";
import Footer from "../../global/Footer/Footer";
import Blog from "./Blog";
import SEO from "../../global/SEO";
import Breadcrumbs from "../../global/Breadcrumbs/Breadcrumbs";
import './BlogPage.css';

const BlogPage = () => {
    const [blogs, setBlogs] = useState([]);
    const [visibleBlogs, setVisibleBlogs] = useState(6);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { blogId } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
  
const generateBreadcrumbs = () => {
  const items = [{ name: 'Главная', path: '/' }];
  items.push({ name: 'Блог', path: location.pathname });
  return items;
};

const breadcrumbItems = generateBreadcrumbs();

    useEffect(() => {
        const fetchBlogsFromDB = async () => {
            try {
              const blogsRef = collection(db, 'blogs');
              const q = query(blogsRef, orderBy('createdAt', 'desc'));
              const querySnapshot = await getDocs(q);
              const fetchedBlogs = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
              }));
              setBlogs(fetchedBlogs);
            } catch (error) {
              console.error("DEBUG: ---> Error fetching blogs:", error);
              setError('Ошибка! Невозможно загрузить блог статью');
            } finally {
              setLoading(false);
            }
          }
        
          fetchBlogsFromDB()
        }, []);

    const handleRedirect = (blogId, slug) => {
        navigate(`/blog/${blogId}/${slug}`);
        window.scrollTo(0, 0);
    }

    if (loading) return <div>Загружаю...</div>;
    if (error) return <div>{error}</div>;
    if (blogId) {
        const blog = blogs.find(b => b.id === blogId);
        return blog ? <Blog blog={blog} /> : <div>Блог статья не найдена</div>;
    }

    const formatDate = (dateString) => {
      const date = new Date(dateString);
      return date.toLocaleDateString('ru-RU', { 
        day: '2-digit', 
        month: '2-digit', 
        year: 'numeric' 
      }).replace(/\//g, '.');
    };
    
    // Assuming the first blog is our hero blog article
    const heroBlog = blogs[0];
    const otherBlogArticles = blogs.slice(1); // This shows the Blogs container where only latest 3 articles are displayed, if set as (0, 4) -> it will be a scrolling carusel
    
    const loadMoreBlogs = () => {
      setVisibleBlogs(prevVisible => prevVisible + 6);
    };
    
    const schemaMarkup = {
      "@context": "https://schema.org",
      "@type": "Blog",
      "name": "Блог ДляДетей.ру",
      "description": "Статьи о развитии и воспитании детей на ДляДетей.ру",
      "url": "https://ddetey.ru/blog",
      "breadcrumb": {
        "@type": "BreadcrumbList",
        "itemListElement": breadcrumbItems.map((item, index) => ({
          "type": "ListItem",
          "position": index + 1,
          "name": item.name,
          "path": `https://ddetey.ru${item.path}`
        }))
      }
    };

    return (
        <div>
          <SEO 
            title="Блог ДляДетей.ру"
            description="Читайте интересные статьи о развитии и воспитании детей на ДляДетей.ру"
            keywords={['блог о детях', 'развитие детей', 'воспитание детей', 'детские занятия', 'советы родителям']}
            image={"/general_images/для-детей-ру-главное-изображение.png"}
            url="https://ddetey.ru/blog"
            ogType="website"
            schemaMarkup={schemaMarkup}
            canonical={`https://ddetey.ru${location.pathname}`}
          />
          <Header />
          <Breadcrumbs items={breadcrumbItems} />
          
            <div className="blog-page-container">
                <div>
                    <div className="bh-image-and-title">
                        <img src={heroBlog.image} alt={heroBlog.title} className="bh-image" />
                        <h2 className="bh-title">{heroBlog.title}</h2>
                    </div>
                    <div className="bh-sub-content">
                        <p className="bh-annotation">{heroBlog.annotation}</p>
                        <span className="bh-date">{formatDate(heroBlog.date)}</span>
                        <button className="bh-button" onClick={() => handleRedirect(heroBlog.id, heroBlog.slug)}>Читать</button>
                    </div>
                </div>

                {/* Other blog articles section */}
                <div className="bpc-blog-articles">
                    {otherBlogArticles.slice(0, visibleBlogs).map(blog => (
                        <div key={blog.id} className="bpc-blog-card">
                          <div className="bpc-blog-article-image-container">
                            <img src={blog.image} alt={blog.title} className="bpc-blog-article-image"/>
                          </div>
                          <div className="bpc-blog-article-title-container">
                            <h3 className="bpc-blog-article-title">{blog.title}</h3>
                          </div>
                            <p className="bpc-blog-article-annotation">{blog.annotation}</p>
                            <p className="bpc-blog-article-date">{formatDate(blog.date)}</p>
                            <button className="bpc-read-the-article-button" onClick={() => handleRedirect(blog.id, blog.slug)}>Читать</button>
                        </div>    
                    ))}
                </div>

                {/* 'Load More' button */}
                {visibleBlogs < otherBlogArticles.length && (
                  <div className="bpc-load-more-button-container">
                    <button className="bpc-load-more-button" onClick={loadMoreBlogs}>
                      Загрузить еще
                    </button>
                  </div>
                )}
            </div>
          <Footer />
        </div>
    );
};

export default BlogPage;