
const categoryData = [
  { id: 'martialArts', name: 'Единоборства', urlSlug: 'edinoborstva', icon: '/category_icons/единоборства.png' },
  { id: 'football', name: 'Футбол', urlSlug: 'futbol', icon: '/category_icons/футбол.png' },
  { id: 'hockey', name: 'Хоккей', urlSlug: 'hokkey', icon: '/category_icons/хоккей.png' },
  { id: 'basketball', name: 'Баскетбол', urlSlug: 'basketbol', icon: '/category_icons/баскетбол.jpeg' },
  { id: 'languages', name: 'Школа Языков', urlSlug: 'shkola-yazykov', icon: '/category_icons/shkola-yazykov.png' },
  { id: 'music', name: 'Музыка', urlSlug: 'muzyka', icon: '/category_icons/музыка.png' },
  { id: 'childCenter', name: 'Детский Центр', urlSlug: 'detskiy-center', icon: '/category_icons/detskij-tsenter.png' },
  { id: 'winterSports', name: 'Зимний Спорт', urlSlug: 'zimniy-sport', icon: '/category_icons/зимний-спорт.png' },
  { id: 'extremeSports', name: 'Экстрим', urlSlug: 'ekstrim', icon: '/category_icons/экстримальный-спорт.png' },
  { id: 'volleyball', name: 'Волейбол', urlSlug: 'voleybol', icon: '/category_icons/волейбол.jpeg' },
  { id: 'gymnastics', name: 'Гимнастика', urlSlug: 'gimnastika', icon: '/category_icons/гимнастика.png' },
  { id: 'athletics', name: 'Атлетика', urlSlug: 'atletika', icon: '/category_icons/легкая-атлетика.png' },
  { id: 'pedagogy', name: 'ОГЭ, ЕГЭ', urlSlug: 'oge', icon: '/category_icons/oge-ege.jpg' },
  { id: 'speechTherapist', name: 'Логопед', urlSlug: 'logoped', icon: '/category_icons/logoped.png' },
  { id: 'dance', name: 'Танцы', urlSlug: 'tantsy', icon: '/category_icons/танцы.jpeg' },
  { id: 'skills', name: 'Навыки', urlSlug: 'navyki', icon: '/category_icons/навыки.png' },
  { id: 'waterSports', name: 'Водный Спорт', urlSlug: 'vodnyj-sport', icon: '/category_icons/водный-спорт.png' },
  { id: 'masterClass', name: 'Мастер-Классы', urlSlug: 'master-klassy', icon: '/category_icons/мастер-класс.jpeg' },
  { id: 'intelligence', name: 'Интеллект', urlSlug: 'intellekt', icon: '/category_icons/развитие-интеллекта.png' },
  { id: 'art', name: 'Искусство', urlSlug: 'iskusstvo', icon: '/category_icons/искусство.jpeg' }
];

export default categoryData;