import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import './SectionOne.css';

const SectionOne = () => {
    const animatedTextRef = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    animatedTextRef.current.classList.add('animate');
                }
            },
            { threshold: 0.5 }
        );

        if (animatedTextRef.current) {
            observer.observe(animatedTextRef.current);
        }

        return () => {
            if (animatedTextRef.current) {
                observer.unobserve(animatedTextRef.current);
            }
        };
    }, []);

    const animatedText = "1 000 000";

    return (
        <div className="hero-section-one">
                <h1 className="hero-section-one-title">
                    Более <span ref={animatedTextRef} className="animated-text">
                        {animatedText.split('').map((char, index) =>(
                            <span key={index} className={`animated-char${char === ' ' ? ' space' : ''}`} style={{animationDelay: `${index * 16}ms`}}>
                                {char === ' ' ? '\u00A0' : char}
                            </span>
                        ))}
                        </span> родителей в Новосибирской области ищут ваши занятия и услуги
                    
                </h1>
                <span className="hero-section-one-subtitle"> 
                Присоединяйтесь и <span style={{fontWeight: "bold"}}>бесплатно</span> размещайте свои занятия и услуги для детей на первой интерактивной карте ДляДетей.ру.
                </span>
                <button className="hero-section-one-button" onClick={() => navigate('/activity-provider/onboarding/step1')}>Присоединиться</button>
        </div>
    );
};

export default SectionOne;