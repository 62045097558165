import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../../../firebase/FirebaseConfig';
import { signInWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';
import { useAuth } from '../../../hooks/useAuth';
import './ActivityProviderLogIn.css';

const ActivityProviderLogIn = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [resetSent, setResetSent] = useState(false);
    const navigate = useNavigate();
    const { user, userRole } = useAuth();

    useEffect(() => {
        setError('');
    }, [email, password]);

    useEffect(() => {
        // If user is already logged in and is an actvity provider
        if (user && userRole === 'activityProvider') {
            navigate('/activity-provider/dashboard');
        }
    }, [user, userRole, navigate]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setLoading(true);

        try {
            await signInWithEmailAndPassword(auth, email, password);
            //navigate('/activity-provider/dashboard');
        } catch (error) {
            console.error("DEBUG: ---> Login error:", error.code, error.message);
            setError('Неправильный email или пароль');
        } finally {
            setLoading(false);
        }
    };

    const handleForgotPassword = async () => {
        if (!email) {
            setError('Пожалуйста, введите email, чтобы поменять пароль');
            return;
        }
        try {
            await sendPasswordResetEmail(auth, email);
            setResetSent(true);
        } catch (error) {
            console.error("DEBUG: ---> Password reset error: ", error.code, error.message);
            setError('Что-то пошло не так. Проверьте, правильно ли Вы написали свой email');
        }
    };

    const usps = [
        "• Получайте новые лиды на автопилоте",
        "• Станьте заметными для родителей",
        "• Масштабируйтесь в другие города",
        "• Экономьте на маркетинге и продвижении"
    ];

    return (
        <div className="activity-provider-login">
                <div className="apl-usp-container">
                <h1><span style={{ cursor: 'pointer '}} onClick={() => navigate('/')}>ДляДетей.ру</span> это интерактивная карта для поиска детских занятий и услуг в вашем городе.</h1>                    
                    <ul>
                        {usps.map((usp, index) =>(
                            <li key={index}>{usp}</li>
                        ))}
                    </ul>
                    <p><span onClick={() => navigate('/terms-of-service')}>Правила Пользования </span> | <span onClick={() => navigate('/privacy-policy')}>Политика Конфиденциальности</span></p>
                </div>
                <div className="apl-login-form-container">
                    <h2>Войти</h2>
                    {error && <p className="apl-error">{error}</p>}
                    <form onSubmit={handleSubmit}>
                        <div className="apl-form-group">
                            <input 
                                type="email"
                                id="email"
                                placeholder="Ваш email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                        </div>
                        <div className="apl-form-group">
                            <input 
                                type="password"
                                id="password"
                                placeholder="Ваш пароль"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                        </div>
                        <button type="submit" disabled={loading}>
                            {loading ? 'Вхожу в аккаунт...' : 'Войти'}
                        </button>
                    </form>
                    <p className="apl-forgot-password">Забыли пароль? <span onClick={handleForgotPassword}>Сменить пароль</span></p>
                    <p className="apl-signup-link">
                        Еще нет аккаунта? <span onClick={() => navigate('/activity-provider/onboarding/step1')}>Создать аккаунт</span>
                    </p>
                    {resetSent && <p className="apl-reset-sent">Ссылка для смены пароля отправлена на Ваш email</p>}
            </div>

            {/* MOBILE */}
            <div className="apl-mobile-layout">
                <div className="apl-mobile-usp-container">
                <h1><span onClick={() => navigate('/')}>ДляДетей.ру</span> это интерактивная карта для поиска детских занятий и услуг в вашем городе.</h1>
                    <ul>
                        {usps.map((usp, index) =>(
                            <li key={index}>{usp}</li>
                        ))}
                    </ul>
                </div>
                <div className="apl-mobile-login-form-container">
                <h2>Войти</h2>
                {error && <p className="apl-error">{error}</p>}
                <form onSubmit={handleSubmit}>
                    <div className="apl-mobile-form-group">
                        <input 
                            type="email"
                            id="email"
                            placeholder="Ваш email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </div>
                    <div className="apl-mobile-form-group">
                        <input 
                            type="password"
                            id="password"
                            placeholder="Ваш пароль"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                    </div>
                    <button type="submit" disabled={loading}>
                        {loading ? 'Вхожу в аккаунт...' : 'Войти'}
                    </button>
                </form>
                    <p className="apl-mobile-forgot-password">Забыли пароль? <span onClick={handleForgotPassword}>Сменить пароль</span></p>
                    <p className="apl-mobile-signup-link">
                        Еще нет аккаунта? <span onClick={() => navigate('/activity-provider/onboarding/step1')}>Создать аккаунт</span>
                    </p>
                    {resetSent && <p className="apl-mobile-reset-sent">Ссылка для смены пароля отправлена на Ваш email</p>}
                </div>
                <p>Правила Пользования | Политика Конфиденциальности</p>
            </div>
        </div>
    );
};

export default ActivityProviderLogIn;