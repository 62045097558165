import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { db } from '../../../../firebase/FirebaseConfig';
import { collection, getDocs } from 'firebase/firestore';
import './BlogList.css';

const BlogList = () => {
  const [blogPosts, setBlogPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    // THIS useEffect fetches blog posts from the Firebase DB
    const fetchExistingBlogPostsFromDB = async () => {
      try {
        const blogCollection = collection(db, 'blogs');
        const blogSnapshot = await getDocs(blogCollection);
        const blogList = blogSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setBlogPosts(blogList);
        setLoading(false);
      } catch (error) {
        console.error("DEBUG ---> Error fetching blogs:", error);
        setError('Ошибка загрузки блогов');
        setLoading(false);
      }
    };

    fetchExistingBlogPostsFromDB();
  }, []);

  const handleEditBlog = (blogId) => {
    navigate(`/admin/blog-list/${blogId}/edit`);
  }

  const handleAddNew = () => {
    navigate('/admin/blog-list/add-blog');
  }

  // This is a "helper" function to safely render the cell content
  const renderCellContent = (content) => {
    if (typeof content === 'string' || typeof content === 'number') {
      return content;
    }
    if (content && typeof content === 'object') {
      return JSON.stringify(content);
    }
    return '';
  };

  if (loading) {
    return <div className="bl-loading">Загрузка...</div>;
  }

  if (error) {
    return <div className="bl-error">{error}</div>;
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('ru-RU', { 
      day: '2-digit', 
      month: '2-digit', 
      year: 'numeric' 
    }).replace(/\//g, '.');
  };

  return (
    <div className="blog-list">
      <div className="bl-list-title">
        <h2>Список блог статей</h2>
        <button className="bl-add-button" onClick={handleAddNew}>Добавить</button>
      </div>
      <span className="bl-list-subtitle">Отображается {blogPosts.length} статей</span>
      <div className="bl-table-container">
        <table>
          <thead>
            <tr>
              <th className="bl-title-column">Заголовок</th>
              <th className="bl-date-column">Дата публикации</th>
            </tr>
          </thead>
          <tbody>
            {blogPosts.map((post) => (
              <tr key={post.id} onClick={() => handleEditBlog(post.id)} className="bl-post-row">
                <td>
                  {post.image && <img src={post.image} alt={`${post.title} thumbnail`} className="bl-post-thumbnail" />}
                  {renderCellContent(post.title)}
                </td>
                <td>{renderCellContent(formatDate(post.date))}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default BlogList;