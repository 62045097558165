import React from 'react';
import { FaCheck } from 'react-icons/fa';
import './WhatWeOffer.css';

const WhatWeOffer = ({ offers }) => {
  return (
    <div className="what-we-offer">
      <ul>
        {offers.map((offer, index) => (
          <li key={index}>
            <FaCheck className="wwo-icon-item" />
            <span>{offer}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default WhatWeOffer;
