import React, { useState } from "react";
import './SectionEight.css';

const SectionEight = () => {
    const [openIndex, setOpenIndex] = useState(null);

    const faqData = [
        {
            question: "Зачем мне размещаться на ДляДетей.ру, если у меня есть свой сайт? ",
            answer: "ДляДетей.ру не заменяет ваш сайт, а усиливает его, являясь дополнительным мощным каналом продвижения, который работает на вас 24/7.",
        },
        {
            question: "Чем ДляДетей.ру отличается от 2ГИС или Яндекс.Карт?",
            answer: "В отличие от общих карт или справочников, ДляДетей.ру специализируется только на занятиях и услугах для детей. Мы предоставляем не только адрес точки на карте и контакты, а структурированную информацию об Организации, которая резонирует с потребностями родителей, и работаем над внедрением новых возможностей для бизнеса, чтобы вы стали еще заметнее для родителей.",
        },
        {
            question: "Чем ДляДетей.ру отличается от Навигатора?",
            answer: "Навигатора дополнительного образования, в первую очередь, ориентирован на управление системой дополнительного образования и обслуживание государственных учреждений, а ДляДетей.ру создан для предпринимателей. В отличие от государственной системы, мы гораздо быстрее внедряем инновации и адаптируемся к потребностям пользователей.",
        },
        {
            question: "Как быстро я начну получать клиентов?",
            answer: "Это зависит от того, насколько хорошо продумано описание Вашего объявления. За последние полгода мы выявили, что полностью заполненные поля в объявлении, продающий текст, актуальное расписание и профессиональные изображения играют ключевую роль.",
        },
        {
            question: "Что если я захочу удалить свой аккаунт, это возможно?",
            answer: "Да, Вы можете удалить свой аккаунт в настройках в личном кабинете. Однако, все Ваши объявления, страница, отзывы родителей, рейтинг и остальные данные удалятся с серверов навсегда. Их нельзя будет восстановить.",
        },
        {
            question: "Надо ли платить процент за полученного клиента?",
            answer: "Нет.",
        },
        {
            question: "Что делать, если для моих занятий и услуг нет подходящей категории?",
            answer: "Некоторые категории еще только формируются и обязательно появятся, поэтому, если Вы не видите нужной Вам категории, напишите на help@ddetey.ru и мы что-нибудь придумаем.",
        },
        {
            question: "Смогу ли я отредактировать объявление после того, как оно будет опубликовано?",
            answer: "Да, Вы можете отредактировать объявление в личном кабинете.",
        },
        {
            question: "А размещаться могут только юридически лица или физические лица тоже?",
            answer: "Размещаться могут юридические лица, физические лица, ИП, Самозанятые, а также НКО и муниципальные учреждения.",
        },
        {
            question: "Что делать, если у меня остались еще вопросы?",
            answer: "Вы всегда можете связаться с нами, написав на help@ddetey.ru",
        }
    ];

    const toggleAnswer = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    return (
        <div className="section-eight">
            <h2 className="section-eight-title">
                часто задаваемые вопросы
            </h2>
            <span className="section-eight-subtitle">
                Здесь собраны ответы на самые актуальные вопросы
            </span>
            <div className="section-eight-faq-container">
                {faqData.map((item, index) => (
                    <div key={index} className={`section-eight-faq-item ${openIndex === index ? 'open' : ''}`} onClick={() => toggleAnswer(index)}>
                        <div className="section-eight-faq-question">
                            <span className="section-eight-faq-number">{String(index + 1).padStart(2, '0')}</span>
                            <span className="section-eight-faq-text">{item.question}</span>
                        </div>
                        <div className="section-eight-faq-answer">
                            {item.answer}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default SectionEight;