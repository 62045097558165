import React, { useState, useEffect, lazy, Suspense } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { db } from '../../../../firebase/FirebaseConfig';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { ref, getStorage, uploadBytes, getDownloadURL } from 'firebase/storage';
import './EditActivityForm.css';

const BasicInfoForm = lazy(() => import('../ActivityFormComponents/BasicInfoForm/BasicInfoForm'));
const AboutUsForm = lazy(() => import('../ActivityFormComponents/AboutUsForm/AboutUsForm'));
const WhatWeOfferForm = lazy(() => import('../ActivityFormComponents/WhatWeOfferForm/WhatWeOfferForm'));
const GroupsForm = lazy(() => import('../ActivityFormComponents/GroupsForm/GroupsForm'));
const ScheduleForm = lazy(() => import('../ActivityFormComponents/ScheduleForm/ScheduleForm'));
const OurLocations = lazy(() => import('../ActivityFormComponents/OurLocations/OurLocations'));
const ImageGalleryForm = lazy(() => import('../ActivityFormComponents/ImageGalleryForm/ImageGalleryForm'));
const PricingForm = lazy(() => import('../ActivityFormComponents/PricingForm/PricingForm'));
const ParentTestimonialsForm = lazy(() => import('../ActivityFormComponents/ParentTestimonialsForm/ParentTestimonialsForm'));
const ContactForm = lazy(() => import('../ActivityFormComponents/ContactForm/ContactForm'));

const EditActivityForm = () => {
    const { activityId } = useParams();
    const [autoSaveIndicator, setAutoSaveIndicator] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [activity, setActivity] = useState({
        basicInfo: { data: {}, isValid: true, error: '' },
        aboutUs: { data: '', isValid: true, error: '' },
        whatWeOffer: { data: [], isValid: true, error: '' },
        groups: { data: [], isValid: true, error: '' },
        schedule: { data: {}, isValid: true, error: '' },
        locations: { data: [], isValid: true, error: '' },
        imageGallery: { data: [], isValid: true, error: '' },
        headCoachBlock: { data: [], isValid: true, error: '' },
        pricing: { data: [], isValid: true, error: '' },
        parentTestimonials: { data: [], isValid: true, error: '' },
        contactInfo: { data: {}, isValid: true, error: '' }
    });
    
    const navigate = useNavigate();

    useEffect(() => {
        const fetchActivityDataFromFirestoreDB = async () => {
            if (!activityId) {
                setError("No activity provider ID provided");
                setLoading(false);
                return;
            }

            try {
                const savedData = localStorage.getItem(`editActivityFormData_${activityId}`);
            if (savedData) {
                const parsedSavedData = JSON.parse(savedData);
                setActivity(parsedSavedData);
                setLoading(false);
                return;
            }
                
                const activityDocRef = doc(db, 'activities', activityId);
                const activityDocSnapshot = await getDoc(activityDocRef);
        
                if (activityDocSnapshot.exists()) {
                    const activityData = activityDocSnapshot.data();
                    console.log("DEBUG! ---> Raw Firebase data:", activityData);

                    const formattedActivity = {
                        basicInfo: { 
                            data: {
                                businessName: activityData.businessName || '',
                                inn: activityData.inn || '',
                                mainImage: activityData.mainImage || null,
                                logo: activityData.logo || null,
                                category: activityData.category || '',
                                mainCity: activityData.mainCity || '',
                                //customCity: activityData.customCity || '',
                                branchQuantity: activityData.branchQuantity || '',
                                availability: activityData.availability || '',
                                price: activityData.price || '',
                        }, isValid: true, error: '' },
                        
                        aboutUs: { data: activityData.aboutUs || '', isValid: true, error: '' },
                        whatWeOffer: { data: activityData.whatWeOffer || [], isValid: true, error: '' },
                        groups: { data: activityData.groups || [], isValid: true, error: '' },
                        schedule: { data: activityData.schedule || {}, isValid: true, error: '' },
                        locations: { data: activityData.locations || [], isValid: true, error: '' },
                        imageGallery: { data: activityData.imageGallery || [], isValid: true, error: '' },
                        // headCoachBlock: { data: data.headCoachBlock.map(coach => ({ ...coach, imageUrl: coach.imageUrl || null })) || [], isValid: true, error: '' },
                        //headCoachBlock: { data: activityData.headCoachBlock || [], isValid: true, error: '' },
                        pricing: { data: activityData.pricing || [], isValid: true, error: '' },
                        parentTestimonials: { data: activityData.parentTestimonials || [], isValid: true, error: '' },
                        contactInfo: { data: activityData.contactInfo || {
                            address: '',
                            subway: '',
                            time: '',
                            phone: '',
                            whatsapp: '',
                            telegram: '',
                            email: ''
                        }, isValid: true, error: '' },
                    };

                    setActivity(formattedActivity);
                    console.log("DEBUG! ---> Formatted activity data:", formattedActivity);
                    setLoading(false);

                } else {
                    throw new Error('Объявление не найдено');
                }
            } catch (err) {
                setError("Странно, но Ваши данные не подгружаются. Попробуйте вновь. Если ошибка повторится, обязательно сообщите об этом help@ddetey.ru или WA/ТГ");
            } finally {
                setLoading(false);
            }
        };

        fetchActivityDataFromFirestoreDB();
    }, [activityId]);

    const autoSave = (data) => {
        try {
            localStorage.setItem(`editActivityFormData_${activityId}`, JSON.stringify(data));
        } catch (error) {
            console.error("DEBUG: Error saving to localStorage:", error);
        }
        setAutoSaveIndicator(true);
        setTimeout(() => setAutoSaveIndicator(false), 1000);
    };

    const handleFormChange = (sectionName, sectionData, isValid, error) => {
        setActivity(prevActivity => {
            const updatedActivity = {
            ...prevActivity,
            [sectionName]: { data: sectionData, isValid, error }
        };
            autoSave(updatedActivity);
            return updatedActivity;
        });
    };

    const uploadImage = async (file, path) => {
        if (!file || typeof file === 'string') return file; // If it is a string, it is already a URL
        const storage = getStorage();
        const storageRef = ref(storage, path);
        await uploadBytes(storageRef, file);
        return await getDownloadURL(storageRef);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);

        // Check if mainImage or logo are present
        if (!activity.basicInfo.data.mainImage || !activity.basicInfo.data.logo) {
            setError('Пожалуйста, загрузите и главное изображение, и логотип перед отправкой формы.');
            setLoading(false);
        }

        //Step 1: identify invalid sections
        const invalidSections = Object.entries(activity)
        .filter(([_, section]) => !section.isValid)
        .map(([name, section]) => ({ name, error: section.error })); 

        if (invalidSections.length > 0) {
            const errorMessages = invalidSections.map(({ name, error }) => `${name}: ${error}`);
            setError(`Пожалуйста, исправьте эту ошибку: ${errorMessages.join(", ")}`);
            setLoading(false);
            return;
        }

        //Step 2: validate forms
        const isFormValid = Object.values(activity).every(section => section.isValid);

        if (isFormValid) {
            try {
                const storage = getStorage();
                const activityRef = doc(db, 'activities', activityId);

                // Handle BasicInfoForm image uploads
                const updatedBasicInfo = {...activity.basicInfo.data};

                if (updatedBasicInfo.mainImage instanceof File) {
                    updatedBasicInfo.mainImage = await uploadImage(updatedBasicInfo.mainImage, `activities/${activityId}/main-image`);
                }

                if (updatedBasicInfo.logo instanceof File) {
                    updatedBasicInfo.logo = await uploadImage(updatedBasicInfo.logo, `activities/${activityId}/logo`);
                }

                // Handle ImageGalleryForm image uploads
                const updatedGallery = await Promise.all(activity.imageGallery.data.map(async (img, index) => {
                    if (img instanceof File) {
                        const imageRef = ref(storage, `activities/${activityId}/gallery/image${index}`);
                        await uploadBytes(imageRef, img);
                        return await getDownloadURL(imageRef);
                    }
                    return img;
                }))

                const updatedActivityData = {
                    businessName: activity.basicInfo.data.businessName,
                    inn: activity.basicInfo.data.inn,
                    category: updatedBasicInfo.category,
                    branchQuantity: activity.basicInfo.data.branchQuantity,
                    availability: updatedBasicInfo.availability,
                    price: updatedBasicInfo.price,
                    //mainImageUrl,
                    //logoUrl,
                    basicInfo: updatedBasicInfo, //activity.basicInfo.data,
                    mainImage: updatedBasicInfo.mainImage,
                    logo: updatedBasicInfo.logo,
                    mainCity: updatedBasicInfo.mainCity,
                    aboutUs: activity.aboutUs.data,
                    whatWeOffer: activity.whatWeOffer.data,
                    groups: activity.groups.data,
                    schedule: activity.schedule.data,
                    locations: activity.locations.data.map(location => ({
                        city: location.city === 'other' && location.customCity ? location.customCity : location.city,
                        addresses: location.addresses
                    })),
                    imageGallery: updatedGallery,
                    //headCoachBlock: updatedHeadCoachBlock,
                    pricing: activity.pricing.data,
                    parentTestimonials: activity.parentTestimonials.data,
                    contactInfo: activity.contactInfo.data,
                    isActive: true, // THIS sets the form to 'true' when the user submits it
                    isComplete: true, // THIS indicates the activity that it can now be displayed inside NewActivity and Activities
                    updatedAt: new Date()
                };

                // Step 1: THIS updates 'activities' collection
                await updateDoc(activityRef, updatedActivityData);

                // Step 2: fetch the updated activity document
                const updatedActivitySnapshot = await getDoc(activityRef);
                const updatedActivity = updatedActivitySnapshot.data();

                // Step 3: update 'activityProviders' collection
                if (updatedActivity.providerId) {
                    const activityProviderRef = doc(db, 'activityProviders', updatedActivity.providerId);
                    const activityProviderUpdate = {
                        businessName: updatedActivity.basicInfo.businessName,
                        inn: updatedActivity.basicInfo.inn,
                        category: updatedActivity.basicInfo.category,
                        logoUrl: updatedActivity.basicInfo.logo,
                        mainCity: updatedActivity.basicInfo.mainCity,
                    };
                
                    await updateDoc(activityProviderRef, activityProviderUpdate);
                } else {
                    console.warn("DEBUG: ! ---> No providerId found in activity data, skipping AP step");
                }

                localStorage.removeItem(`editActivityFormData_${activityId}`);
                navigate('/activity-provider/dashboard');
            } catch (err) {
                console.error("DEBUG: Error updating activity and/or activityProvider after the edit: ", err);
                setError("Ошибка! Пожалуйста, убедитесь, что вес фотографий не превышает 1 мб.");
            } finally {
                setLoading(false);
            }
        } else {
            setError("Пожалуйста, исправьте ошибки в форме.");
            setLoading(false);
        }
    };

    if (loading) return <div className="eaf-loading">Загрузка...</div>

    // if (error) {
    //     return <div className="eaf-error">{error}</div>;
    // }

    if (!activity) return <div className="eaf-error">Объявление не найдено :(</div>;

    // const FormDivider = () => {
    //     return <hr className="form-divider" />;
    // };

    return (
        <div className="edit-activity-form-container">
            <h2>Главное объявление</h2>
            {autoSaveIndicator && <div className="eaf-auto-save-indicator">Сохранение...</div>}
                        
            <div className="edit-activity-form">
                <form onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit(e)}}>
   
                    <Suspense fallback={<div>Loading...</div>}>
                        <BasicInfoForm
                            data={activity.basicInfo.data}
                            onChange={(data, isValid, error) => handleFormChange('basicInfo', data, isValid, error)}
                        />
                        {/* <FormDivider /> */}
                        <AboutUsForm
                            data={activity.aboutUs.data}
                            onChange={(data, isValid, error) => handleFormChange('aboutUs', data, isValid, error)}
                        />
                        {/* <FormDivider /> */}
                        <WhatWeOfferForm
                            data={activity.whatWeOffer.data}
                            onChange={(data, isValid, error) => handleFormChange('whatWeOffer', data, isValid, error)}
                        />
                        {/* <FormDivider /> */}
                        <GroupsForm 
                            data={activity.groups.data}
                            onChange={(data, isValid, error) => handleFormChange('groups', data, isValid, error)}
                        />
                        {/* <FormDivider /> */}
                        <ScheduleForm
                            data={activity.schedule.data}
                            onChange={(data, isValid, error) => handleFormChange('schedule', data, isValid, error)}
                        />
                        {/* <FormDivider /> */}
                        <OurLocations
                            data={activity.locations.data}
                            onChange={(data, isValid, error) => handleFormChange('locations', data, isValid, error)}
                            mainCity={activity.basicInfo.data.mainCity}
                            customCity={activity.basicInfo.data.customCity}
                        />
                        {/* <FormDivider /> */}
                        <ImageGalleryForm
                            data={activity.imageGallery.data}
                            onChange={(data, isValid, error) => handleFormChange('imageGallery', data, isValid, error)}
                        />
                        {/* <FormDivider />
                        <HeadCoachForm 
                            data={activity.headCoachBlock.data}
                            onChange={(data, isValid, error) => handleFormChange('headCoachBlock', data, isValid, error)}
                        /> */}
                        {/* <FormDivider /> */}
                        <PricingForm
                            data={activity.pricing.data}
                            onChange={(data, isValid, error) => handleFormChange('pricing', data, isValid, error)}
                        />
                        {/* <FormDivider /> */}
                        <ParentTestimonialsForm
                            data={activity.parentTestimonials.data}
                            onChange={(data,isValid, error) => handleFormChange('parentTestimonials', data, isValid, error)}
                        />
                        {/* <FormDivider /> */}
                        <ContactForm 
                            data={activity.contactInfo.data}
                            onChange={(data, isValid, error) => handleFormChange('contactInfo', data, isValid, error)}
                        /> 
                    </Suspense>

                    <button type="submit" className="eaf-submit-button" disabled={loading}>
                        {loading ? 'Сохранение изменений...' : 'Опубликовать объявление'}
                    </button>
                </form>
            </div>
            {error && (
                <div className="eaf-error-message">
                    {error}
                </div>
            )}
        </div>
    );
};

export default EditActivityForm;