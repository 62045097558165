import React from "react";
import "./SectionSeven.css";

const parentTestimonials = [
    {
        testimonial: "То, что нужно для занятых родителей. У меня 4 детей, каждому из них хочется подобрать занятие по душе, желательно недалеко от дома — а здесь все собрано в общий каталог с удобным поиском, есть описание и рейтинг. Классный проект!",
        parentName: "Денис Булаев",
        jobTitle: "Секвения"
    },
    {
        testimonial: "Удобный и полезный сервис для родителей, в котором можно в режиме одного окна выбрать и оплатить кружок для ребёнка, а также сопутствующие товары—форму, аксессуары и не только.",
        parentName: "Дарья Юрикас",
        jobTitle: "Журнал 'Деловой Квартал'"
    },
    {
        testimonial: "Это очень удобное для родителей приложение, где можно знакомиться с образовательной и развивающей структурой для детей и подростков.",
        parentName: "Марина Блинова",
        jobTitle: "Администрация г. Карачев (Брянская Область)"
    },
    {
        testimonial: "Хороший, красочный сайт, все интуитивно и понятно. Облегчит муки поиска досуга для ребенка. Фишка интеграции с магазинами инвентаря—вообще огонь!",
        parentName: "Алексей Ерушев",
        jobTitle: "АРКОН"
    }
];

const SectionSeven = () => {
  return (
    <div className="section-seven">
      <h2 className="section-seven-title">
        Что говорят родители о нас
      </h2>
      <span className="section-seven-subtitle">
        Здесь собрана реальная обратная связь от родителей,
        с которыми мы пообщались.
      </span>
      <div className="section-seven-testimonials">
        {parentTestimonials.map((testimonial, index) => (
          <div key={index} className={`sst-${index + 1}`}>
            <span className="sst-quote-sign">‟</span>
            <p className={`sst-${index + 1}-p`}>{testimonial.testimonial}</p>
            <span className={`sst-parent-name-${index + 1}`}>{testimonial.parentName}</span>
            <span className={`sst-parent-job-${index + 1}`}>{testimonial.jobTitle}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SectionSeven;