import React, { useState, useEffect } from 'react';
import './Schedule.css';

const daysOrder = ['Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота', 'Воскресенье'];

const Schedule = ({ schedule }) => {
  const [activeDays, setActiveDays] = useState([]);
  const [selectedDay, setSelectedDay] = useState(null);

  useEffect(() => {
    const daysWithClasses = Object.keys(schedule)
      .filter(day => day !== 'isHidden' && Array.isArray(schedule[day]) && schedule[day].length > 0 && schedule[day].some(session => Object.values(session).some(value => value.trim() !== '')))
      .sort((a, b) => daysOrder.indexOf(a) - daysOrder.indexOf(b));

    setActiveDays(daysWithClasses);
    setSelectedDay(daysWithClasses[0] || null);
  }, [schedule]);

  const handleDayClick = (day) => {
    setSelectedDay(day);
  };

  if (activeDays.length === 0) {
    return <div className="schedule">No classes scheduled</div>;
  }

  return (
    <div className="schedule">
      <div className="days">
        {activeDays.map((day) => (
          <div 
            key={day}
            className={`day ${selectedDay === day ? 'active' : ''}`}
            onClick={() => handleDayClick(day)}
          >
            {day}
          </div>
        ))}
      </div>
      {selectedDay && (
        <table>
          <thead>
            <tr>
              <th>Группа</th>
              <th>Время</th>
              <th>Педагог</th>
              <th>Адрес</th>
            </tr>
          </thead>
          <tbody>
            {schedule[selectedDay]?.map((session, index) => (
              <tr key={index}>
                <td>{session.group}</td>
                <td>{session.time}</td>
                <td>{session.teacher}</td>
                <td>{session.room}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default Schedule;