import React from 'react';
import { FaLock } from 'react-icons/fa';
import categoryData from '../../../data/categoryData';
import './category-bar.css';

function CategoryBar({ onCategoryClick, availableCategories, activeCategory, className }) {
  const handleCategoryClick = (categoryId) => {
    if (availableCategories.includes(categoryId)) {
      onCategoryClick(categoryId);
    }
  };

  // THIS sorts categories: available first, then locked
  const sortedCategories = categoryData.sort((a, b) => {
    const aAvailable = availableCategories.includes(a.id);
    const bAvailable = availableCategories.includes(b.id);
    return bAvailable - aAvailable;
  })

  return (
    <div className={`category-bar ${className || ''}`}>
      {sortedCategories.map(category => {
        const isAvailable = availableCategories.includes(category.id);
        const isActive = activeCategory === category.id;
        return (
          <div
            key={category.id}
            className={`category-item ${isActive ? 'active' : ''} ${!isAvailable ? 'locked' : ''}`}
            onClick={() => handleCategoryClick(category.id)}
          >
            <div className="cb-category-item-container">
              <img src={category.icon} alt={category.name} className="category-icon" onError={(e) => {
                console.error(`DEBUG! ---> failed to load icon for ${category.name}:`, category.icon);
                e.target.src = categoryData.find(c => c.id === category.id).icon;
              }}/>
              {!isAvailable && <FaLock className="cb-lock-icon" />}
            </div>
            <p className="category-title">{category.name}</p>
            {/* {!isAvailable && <FaLock className="cb-lock-icon" />} */}
          </div>
        )
      })}
    </div>
  );
}

export default CategoryBar;