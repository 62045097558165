import React from 'react';
import { FaMapMarkerAlt, FaPhone, FaWhatsapp,  FaTelegram, FaEnvelope } from 'react-icons/fa';
import { SiMoscowmetro } from 'react-icons/si';
import { IoTimeOutline } from "react-icons/io5";
import './ContactBlock.css';

const Contact = ({ contactInfo, onKeyAction }) => {

  // Helper function to check if a value is empty or undefined
  const isEmptyForm = (value) => !value || value.trim() === '';

  const handleCallClick = () => {
    onKeyAction('call');
    window.location.href = `tel:${contactInfo.call}`;
  };

  const handleWhatsAppClick = () => {
    onKeyAction('whatsapp');
    const message = "Здравствуйте! Я хочу узнать подробнее о ваших занятиях. Можете рассказать?";
    const url = `https://api.whatsapp.com/send?phone=${contactInfo.whatsapp}&text=${encodeURIComponent(message)}`;
    window.open(url, '_blank');
  }

  const handleTelegramClick = () => {
    onKeyAction('telegram');
    const message = "Здравствуйте! Я хочу узнать подробнее о ваших занятиях. Можете рассказать?";
    const url = `https://t.me/${contactInfo.telegram}?text=${encodeURIComponent(message)}`;
    window.open(url, '_blank');
  }

  const handleEmailClick = () => {
    onKeyAction('email');
    const subject = "Запрос информации о занятиях";
    const body = "Здравствуйте! Я хочу узнать подробнее о ваших занятиях. Можете рассказать?";
    window.location.href = `mailto:${contactInfo.email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
  }

  // Array of contact items with their respective icons and data
  const contactItems = [
    { icon: FaMapMarkerAlt, data: contactInfo.address },
    { icon: SiMoscowmetro, data: contactInfo.subway },
    { icon: IoTimeOutline, data: contactInfo.time },
    { icon: FaPhone, data: 'Позвонить', onClick: handleCallClick },
    { icon: FaWhatsapp, data: 'Написать в WhatsApp', onClick: handleWhatsAppClick },
    { icon: FaTelegram, data: 'Написать в Телеграм', onClick: handleTelegramClick },
    { icon: FaEnvelope, data: 'Написать на Email', onClick: handleEmailClick }
  ];

  return (
    <div className="contact">
      <div className="contact-info">
        {contactItems.map((item, index) => (
          !isEmptyForm(item.data) && (
            <div 
              key={index} 
              className={`contact-item ${item.onClick ? 'clickable' : ''}`}
              onClick={item.onClick}
            >
              <item.icon className="contact-icon" />
              <p>{item.data}</p>
            </div>
          )
        ))}
      </div>
    </div>
  );
};

export default Contact;
