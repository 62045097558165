import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { db } from '../../../../firebase/FirebaseConfig';
import { collection, getDocs } from 'firebase/firestore';
import './FairytalesList.css';

const FairytalesList = () => {
  const [fairytales, setFairytales] = useState([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchFairytalesfromDB = async () => {
      try {
        const fairytalesCollection = collection(db, 'fairytales');
        const fairytaleSnapshot = await getDocs(fairytalesCollection);
        const fairytaleList = fairytaleSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setFairytales(fairytaleList);
        setLoading(false);
      } catch (error) {
        console.error("DEBUG: ---> Error fetching fairytales:", error);
        setError('Ошибка загрузки сказок');
        setLoading(false);
      }
    };

    fetchFairytalesfromDB();
  }, []);

  const handleEditFairytale = (fairytaleId) => {
    navigate(`/admin/fairytales-list/${fairytaleId}/edit`);
  }

  const handleAddNew = () => {
    navigate('/admin/fairytales-list/add-fairytale');
  }

  // This is a "helper" function to safely render the cell content
  const renderCellContent = (content) => {
    if (typeof content === 'string' || typeof content === 'number') {
      return content;
    }
    if (content && typeof content === 'object') {
      return JSON.stringify(content);
    }
    return '';
  };

  if (loading) return <div className="fl-loading">Загрузка...</div>;
  if (error) return <div className="fl-error">{error}</div>;

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('ru-RU', { 
      day: '2-digit', 
      month: '2-digit', 
      year: 'numeric' 
    }).replace(/\//g, '.');
  };

  return (
    <div className="fairytale-list">
      <div className="fl-list-title">
        <h2>Список сказок</h2>
        <button className="fl-add-button" onClick={handleAddNew}>Добавить</button>
      </div>
      <span className="fl-list-subtitle">Отображается {fairytales.length} сказок</span>
      <div className="fl-table-container">
        <table>
          <thead>
            <tr>
              <th className="fl-title-column">Заголовок</th>
              <th className="fl-date-сolumn">Дата публикации</th>
            </tr>
          </thead>
          <tbody>
            {fairytales.map((fairytale) => (
              <tr key={fairytale.id} onClick={() => handleEditFairytale(fairytale.id)} className="fl-fairytale-row">
                <td>
                  {fairytale.image && <img src={fairytale.image} alt={`${fairytale.title} thumbnail`} className="fl-tale-thumbnail" />}
                  {renderCellContent(fairytale.title)}
                </td>
                <td>{renderCellContent(formatDate(fairytale.date))}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default FairytalesList;