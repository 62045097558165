import React from 'react';
import './SpecialOfferModal.css';

const SpecialOfferModal = ({ offer, mainImage, onClose, onKeyAction, contactInfo }) => {
    
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('ru-RU', { 
          day: '2-digit', 
          month: '2-digit', 
          year: 'numeric' 
        }).replace(/\//g, '.');
      };

      const handleCallClick = () => {
        onKeyAction('call');
        window.location.href = `tel:${contactInfo.Call}`;
      };
    
      const handleWhatsAppClick = () => {
        onKeyAction('whatsapp');
        const message = "Здравствуйте! Я хочу узнать подробнее о ваших занятиях. Можете рассказать?";
        const url = `https://api.whatsapp.com/send?phone=${contactInfo.whatsapp}&text=${encodeURIComponent(message)}`;
        window.open(url, '_blank');
      }
    
      const handleEmailClick = () => {
        onKeyAction('email');
        const subject = "Запрос информации о занятиях";
        const body = "Здравствуйте! Я хочу узнать подробнее о ваших занятиях. Можете рассказать?";
        window.location.href = `mailto:${contactInfo.email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
      }

      const keyActionText = [
        { key: 'call', text: 'Позвонить', onClick: handleCallClick },
        { key: 'whatsapp', text: 'Написать в WhatsApp', onClick: handleWhatsAppClick },
        { key: 'email', text: 'Отправить заявку на Email', onClick: handleEmailClick }
    ];

    const actionItem = keyActionText.find(item => item.key === offer.keyAction);

  return (
    <div className="special-offer-modal" onClick={onClose}>
      <div className="special-offer-modal-content" onClick={(e) => e.stopPropagation()}>
        <button className="close-button" onClick={onClose}>×</button>
        <div className="special-offer-modal-body">
          <div className="special-offer-modal-image">
            <img src={mainImage} alt="Activity" />
          </div>
          <div className="special-offer-modal-info">
            <h2>{offer.title}</h2>
            <p className="somi-description">{offer.description}</p>
            <p className="somi-offer-dates">Акция действует: <br/>{formatDate(offer.startDate)} - {formatDate(offer.endDate)}</p>
            <p className="somi-available-spots">Осталось {offer.availableSpots} свободных мест</p>
            {actionItem && (
                <button className="special-offer-action-button" onClick={actionItem.onClick}>
                    {actionItem.text}
                </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SpecialOfferModal;