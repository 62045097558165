export const allKeywords = [
    // Brand
    'длядетей.ру', 
    'ddetey.ru', 
    'длядетей ру карта',
    'ddetey',
    'для детей ру',
    'длядетей ру',
    'длядетей ру вк',
    'длядетей ру вконтакте',
    'длядетей ру телеграм',
    'длядетей ру тг',
    'ddetey ru',
    'dlyadetey.ru',
    'dlyadetey ru',

    // Common search patterns with brand
    'длядетей.ру сайт',
    'для детей ру сайт',
    'длядетей ру сайт',
    'сайт для детей',
    'сайт для детей ру',
    'сайт длядетей',
    'длядетей ру официальный сайт',
    'длядетей.ру новосибирск',
    'длядетей ру новосибирск',
    'для детей ру новосибирск',
    'длядетей.ру бердск',
    'длядетей ру бердск',
    'для детей ру бердск',
    'длядетей.ру академгородок',
    'длядетей ру академгородок',
    'для детей ру академгородок',
    'длядетей.ру искитим',
    'длядетей ру искитим',
    'для детей ру искитим',
    'длядетей.ру отзывы',
    'длядетей ру отзывы',
    'длядетей.ру регистрация',
    'длядетей ру регистрация',
    'для детей ру регистрация',
    'для детей ру отзывы',

    // For activity providers
    'для детей ру для бизнеса',
    'для детей ру разместить занятие',
    'для детей ру добавить секцию',
    'длядетей ру добавить объявление',
    'размещение детских занятий и услуг',
    'реклама детских занятий',
    'продвижение детских занятий и услуг',
    'партнерская программа для детских центров',
    'как привлекать родителей',
    
    // Location-specific searches (these are gold for map-based services!)
    'детские секции рядом со мной',
    'детские занятия рядом с домом',
    'детский центр поблизости',
    'спортивная секция рядом',
    'бассейн для детей рядом',
    'детские кружки в моем районе',
    'занятия для детей поблизости',
    'детские занятия метро',
    'детские кружки метро',
    'спортивные секции у метро',
    'спортивная школа рядом',
    'занятия для детей возле дома',
    'детский центр в пешей доступности',
    'кружки рядом со школой',
    'секции по пути из шшколы',
    'детские занятия в радиусе',
    'бесплатные кружки новосибирск',
    'бесплатные кружки бердск',
    'бесплатные кружки академгородок',
    'бесплатные кружки искитим',
    'споривная школа рядом',
    'детский центр поблизости',
    'детский тренер новосибирск',
    'детский тренер бердск',
    'детский тренер академгородок',
    'детский тренер искитим',
    
    // City-specific 
    'детские секции новосибирск',
    'кружки для детей новосибирск',
    'детские занятия в академгородке',
    'секции для детей бердск',
    'детские центры искитим',
    'детские занятия октябрьский район',
    'детские кружки октябрьский район',
    'секции ленинский район',
    'детские центры калининский район',
    'детские центры первомайский район',
    'детские центры железнодорожный район',
    'детские центры октябрьский район',
    
    // Map-specific searches
    'карта детских занятий',
    'детские секции на карте',
    'найти занятия для ребенка на карте',
    'детские кружки показать на карте',
    'спортивные секции для детей на карте',
    'найти детские секции на карте',
    'поиск кружков рядом',
    'детские занятия поблизости карта',
    'ближайшие детские центры',
    
    // Activity + Location combinations
    'бассейн для детей рядом со мной',
    'детский футбол рядом с домом',
    'танцы для детей поблизости',
    'английский для детей рядом',
    'карате для детей рядом с домом',
    'гимнастика для детей рядом',
    'логопед рядом со мной',
    'подготовка к школе рядом с домом',
    'пробное занятия поблизости',
    'бесплатные детские занятия рядом',
    'детский центр со скидкой',
    'недорогие кружки для детей',
    'самбо для детей',
    'карате детям от 3',
    'дзюдо первое занятие',
    'единоборства для детей рядом',
    'спортивные занятия поблизости',
    'тренировки для детей рядом',
    
    // General searches (still important)
    'куда отдать ребенка',
    'чем занять ребенка',
    'детские занятия',
    'секции для детей',
    'кружки для детей',
    'детский спорт',
    'развивающие занятия',
    'групповые занятия для детей',
    'индивидуальные тренировки для детей',
    'пробное занятие для ребенка',
    'записаться на тренировку',
    'тренер по',
    'расписание детских занятий',
    'единоборства для детей',
    'танцевальная студия',
    'музыкальная школа',
    'занятия вокалом',
    'игра на гитаре',
    'репетитор онлайн',

    
    // Popular activities
    'развитие ребенка',
    'детские спортивные секции',
    'детская сопртивная школа',
    'танцы для детей',
    'английский для детей',
    'карате для детей',
    'футбол для детей',
    'гимнастика для детей',
    'бассейн для детей',
    'подготовка к школе',
    'логопед для детей',
    'репетитор для ребенка',
    'детские тренеры',
    'спортивная школа',
    'музыкальная школа',
    'школа танцев',
    'робототехника для детей',
    'программирование для детей',
    'шахматы для детей',
    'мастер-классы для детей',

    // Location-specific patterns (expanded)
    'детские секции рядом со мной',
    'детские занятия рядом с домом',
    'детский центр поблизости',
    'занятия для детей в моем районе',
    'детские кружки возле дома',
    'секции для детей около меня',
    'детский центр в пешей доступности',
    'занятия для детей в радиусе 1 км',
    'детские секции в нашем районе',
    
    // District-specific (Novosibirsk)
    'детские секции ленинский район',
    'занятия для детей октябрьский район',
    'детские кружки калининский район',
    'секции для детей дзержинский район',
    'детский центр кировский район',
    'занятия первомайский район',
    'кружки для детей советский район',
    'детские занятия центральный район',
    
    // Transport-based searches
    'детские секции рядом с метро',
    'занятия для детей у метро',
    'детский центр возле остановки',
    'кружки для детей с удобной транспортной доступностью',
    
    // Time/Distance based
    'детские секции 5 минут от дома',
    'занятия для детей 10 минут пешком',
    'детские кружки в 15 минутах езды',
    'детский центр недалеко от дома',
    
    // Micro-district specific
    'детские секции академгородок',
    'занятия для детей золотая нива',
    'детские кружки березовая роща',
    'секции для детей академгородок',
    'детский центр заельцовская',
    'занятия для детей',
    'кружки для детей',
    
    // Specific venue proximity
    'детские секции рядом со школой',
    'занятия для детей в доме культуры',
    'детские кружки в школе',
    'секции в спорткомплексе',
    'детский центр в торговом центре',
    
    // Complex location intents
    'детские секции по пути из школы',
    'занятия для детей между домом и школой',
    'детские кружки по дороге с работы',
    'удобные занятия для детей по локации',
    'все детские секции в округе',
    'ближайшие занятия для детей',
    
    // Parent-specific location needs
    'детские секции где удобно ждать родителям',
    'занятия для детей с парковкой',
    'детские кружки с комфортной зоной ожидания',
    'секции для детей с удобным расположением'
];