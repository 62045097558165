import React, { useState } from 'react';
import './Groups.css';

const Groups = ({ groups }) => {
  const [expandedGroup, setExpandedGroup] = useState(null);

  // Does not display Groups if isHidden = true
  if (!groups || (typeof groups === 'object' && groups.isHidden)) return null;

  const groupsData = Array.isArray(groups) ? groups : Object.values(groups).filter(item => item.name);

  const handleToggle = (index) => {
    setExpandedGroup(expandedGroup === index ? null : index);
  };

  return (
    <div className="groups">
      {groupsData.map((group, index) => (
        <div key={index} className="group">
          <div className="group-header" onClick={() => handleToggle(index)}>
            <h3>{group.name}</h3>
            <span className="toggle-icon">
              {expandedGroup === index ? '−' : '+'}
            </span>
          </div>
          {expandedGroup === index && (
            <div 
              className="group-description"
              dangerouslySetInnerHTML={{ __html: group.description }}
            />
          )}
        </div>
      ))}
    </div>
  );
};

export default Groups;