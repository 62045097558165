import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { collection, query, orderBy, getDocs } from 'firebase/firestore';
import { db } from '../../../firebase/FirebaseConfig';
import Header from '../../global/Header/Header';
import Footer from '../../global/Footer/Footer';
import Skazka from './Skazka';
import SEO from '../../global/SEO';
import Breadcrumbs from '../../global/Breadcrumbs/Breadcrumbs';
import './SkazkiPage.css';

const SkazkiPage = () => {
    const [skazki, setSkazki] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { skazkaId } = useParams();
    const navigate = useNavigate();
    const location = useLocation();

const generateBreadcrumbs = () => {
    const items = [{ name: 'Главная', path: '/' }];
    items.push({ name: 'Сказки', path: location.pathname });
    return items;
};

const breadcrumbItems = generateBreadcrumbs();
  
    useEffect(() => {
      const fetchSkazkiFromDB = async () => {
        try {
          const skazkiRef = collection(db, 'fairytales');
          const q = query(skazkiRef, orderBy('createdAt', 'desc'));
          const querySnapshot = await getDocs(q);
          const fetchedSkazki = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));
          setSkazki(fetchedSkazki);
        } catch (error) {
          console.error("DEBUG: ---> Error fetching skazki:", error);
          setError('Ошибка! Невозможно загрузить сказки');
        } finally {
          setLoading(false);
        }
      }
      fetchSkazkiFromDB();
    }, []);
  
    const handleRedirect = (skazkaId, slug) => {
        navigate(`/skazki/${skazkaId}/${slug}`);
        window.scrollTo(0, 0);
    }
  
    if (loading) return <div>Загружаю...</div>;
    if (error) return <div>{error}</div>;
    if (skazkaId) {
        const skazka = skazki.find(s => s.id === skazkaId);
        return skazka ? <Skazka skazka={skazka} /> : <div>Сказка не найдена</div>;
    }

    const schemaMarkup = {
        "@context": "https://schema.org",
        "@type": "CollectionPage",
        "name": "Сказки для детей",
        "description": "Авторские сказки для детей от команды ДляДетей.ру",
        "url": "https://ddetey.ru/skazki",
        "breadcrumb": {
            "@type": "BreadcrumbList",
            "itemListElement": breadcrumbItems.map((item, index) => ({
                "@type": "ListItem",
                "position": index + 1,
                "name": item.name,
                "item": `https://ddetey.ru${item.path}`
            }))
        }
      };

    return (
        <div>
            <SEO
                title="Сказки для детей"
                description="Читайте авторские сказки для детей от команды ДляДетей.ру, которые, как все русские сказки, учат чем-то доброму."
                keywords={['сказки', 'сказка про', 'народные сказки', 'слушать сказку', 'сказка царевна', 'русские сказки', 'читаем сказки', 'сказки читать', 'сказки для детей', 'сказка 3', 'русские народные сказки', 'сказки на ночь', 'детские для детей', 'слушать для детей', 'сказки на ночь для детей', 'аудиосказки для детей', 'развивающие для детей', 'сказки для детей слушать', 'детские сказки', 'аудиосказки', 'развитие детей', 'русская литература']}
                image="skazki_block/для-детей-ру-сказки.jpeg" 
                url="https://ddetey.ru/skazki" 
                ogType="website"
                schemaMarkup={schemaMarkup}
                canonical={`https://ddetey.ru${location.pathname}`}
            />
            <Header />
            <Breadcrumbs items={breadcrumbItems} />

                <div className="hero-container">
                    <img src="skazki_block/для-детей-ру-сказки.jpeg" alt="Hero" className="hero-image" />
                        <div>
                            <h1 className="hc-hero-title">СКАЗКИ ДЛЯ ДЕТЕЙ</h1>
                            <p className="hc-hero-subtitle">Добро пожаловать в раздел со сказками. <br/>Все сказки, включая рисунки, анимацию и озвучку делает ДляДетей.ру</p>
                        </div>
                </div>

            {/* here we can add either a sign up form or 3 blocks with WHY, ie 1) unique 2) weekly 3) 50k on YuoTube */}
            
                <div className="s-usp-grid">
                    <div className="s-usp-1">
                        <p className="s-usp-1-p">Можно читать вслух, а можно послушать в формате аудио.</p>
                    </div>
                    <div className="s-usp-2">
                        <p className="s-usp-2-p">Ненавязчиво учим детей важным жизненным ценностям.</p>
                    </div>
                    <div className="s-usp-3">
                        <p className="s-usp-3-p">Сказки, пропитанные духом нашей культуры и традиции.</p>
                    </div>
                    <div className="s-usp-4">
                        <p className="s-usp-4-p">Зжигаем огонек фантазии в каждом юном сердце.</p>
                    </div>
                    <div className="s-usp-5">
                        <p className="s-usp-5-p">Истории, которые не слышали даже ваши бабушки и дедушки.</p>
                    </div>
                    <div className="s-usp-6">
                        <p className="s-usp-6-p">Учим справляться с трудностями на примере сказочных ситуаций.</p>
                    </div>
                </div>

                <div className="list-container">
                    {skazki.map((skazka) => (
                        <div key={skazka.id}>
                            <div className="skazka-image-and-title">
                                <img src={skazka.image} alt={skazka.title} className="skazka-image" />
                                <h2 className="skazka-title">{skazka.title}</h2>
                            </div>
                                <p className="skazka-description">{skazka.annotation}</p>
                                <button className="skazka-button" onClick={() => handleRedirect(skazka.id, skazka.slug)}>Читать</button>
                        </div>
                     ))}
                </div>
            <Footer />
        </div>
    );
};

export default SkazkiPage;