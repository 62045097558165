import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import Header from "../../global/Header/Header.js";
import Footer from "../../global/Footer/Footer.js";
import SectionOne from "./SectionOne/SectionOne.js";
import SectionTwo from "./SectionTwo/SectionTwo.js";
import SectionThree from "./SectionThree/SectionThree.js";
import SectionFour from "./SectionFour/SectionFour.js";
import SectionFive from "./SectionFive/SectionFive.js";
import SectionSix from "./SectionSix/SectionSix.js";
import SectionSeven from "./SectionSeven/SectionSeven.js";
import SectionEight from "./SectionEight/SectionEight.js";
import SectionNine from "./SectionNine/SectionNine.js";
import SEO from "../../global/SEO.js";
import Breadcrumbs from "../../global/Breadcrumbs/Breadcrumbs.js";

const JoinUs = () => {
    const [logoUrls, setLogoUrls] = useState([]);
    const location = useLocation();

const generateBreadcrumbs = () => {
    const items = [{ name: 'Главная', path: '/' }];
    items.push({name: 'Присоединиться', path: '/join-us' });
    return items;
};

const breadcrumbItems = generateBreadcrumbs();

    useEffect(() => {
        const fetchLogosFromStorage = async () => {
            const storage = getStorage();
            const cherryPickedLogos = [
                'OBv2Fv8nJ4XMtZBPCm1s',
                'yKhOhHBMhrYYmIcbsLvy',
                'XFOHufak0HgPwVuEik7Z',
                'HHxgpuo0vh0ZNGNYqN5x',
                'KXS225SJWIox56dQbiRy'
            ];

            try {
                const urls = await Promise.all(
                    cherryPickedLogos.map(async (activityId) => {
                        const logoRef = ref(storage, `activities/${activityId}/logo`);
                        return await getDownloadURL(logoRef);
                    })
                );
                setLogoUrls(urls);
            } catch (error) {
            }
        };

        fetchLogosFromStorage();
    }, []);

    const schemaMarkup = {
        "@context": "https://schema.org",
        "@type": "WebPage",
        "name": "Присоединиться к ДляДетей.ру",
        "url": "https://ddetey.ru/join-us",
        "description": "Более 1 000 000 родителей в Новосибирской области ищут ваши занятия и услуги. Присоединяйтесь бесплатно к единому каталогу всех занятий и услуг для детей.",
        "breadcrumb": {
            "@type": "BreadcrumbList",
            "itemListElement": breadcrumbItems.map((item, index) => ({
              "@type": "ListItem",
              "position": index + 1,
              "name": item.name,
              "item": `https://ddetey.ru${item.path}`
            }))
          }
      };
    
    return (
        <div>
            <SEO
                title="ДляДетей.ру"
                description="Более 1 000 000 родителей в Новосибирской области ищут ваши занятия и услуги. Присоединяйтесь бесплатно к единому каталогу всех занятий и услуг для детей."
                keywords={['длядетей.ру', 'ddetey.ru', 'ddetey ru', 'ddeteyru', 'для детей ру', 'длядетейру', 'длядетей ру', 'для детей', 'дети', 'для ребенка', 'сказки', 'слушать скзаку', 'аудиосказки для детей', 'кидс', 'тренер по', 'развитие ребенка', 'карате', 'бесплатно для детей', 'детские занятия', 'детский центр', 'спортивная школа', 'dlya detey ru', 'dlyadetey.ru', 'dlyadeteyru', 'услуги для детей', 'навигатор дополнительного образования', 'репетитор для ребенка', 'егэ', 'огэ', 'единоборства', 'музыка', 'мастер класс для детей', 'логопед', 'танцы', 'брейк данс', 'k pop', 'группа продленного дня', 'музыкальная школа', 'вокал', 'вокал для детей', 'игра на гитаре', 'школа языков', 'английский язык', 'китайский язык', 'языковая школа', 'репетитор', 'подготовка к егэ', 'подготовка к огэ', 'гимнастика для детей', 'детская баскетбольная школа', 'баскетбол', 'футбольный клуб', 'футбольная школа', 'волейбол', 'тенис', 'самбо', 'единоборства', 'лекая атлетика', 'робототехника для детей', 'шахматный клуб', 'школа плавания для детей', 'плавание', 'школа фигурного катания', 'школа сноуборда', 'сапы для детей']}
                image="/general_images/для-детей-ру-главное-изображение.png"
                url="https://ddetey.ru/join-us"
                ogType="website"
                schemaMarkup={schemaMarkup}
                canonical={`https://ddetey.ru${location.pathname}`}
            />
            <Header />
            <Breadcrumbs items={breadcrumbItems} />

            <div>
                <SectionOne />
                <SectionTwo />
                <SectionThree />
                <SectionFour />
                <SectionFive />
                <SectionSix logoUrls={logoUrls} />
                <SectionSeven />
                <SectionEight />
                <SectionNine />
            </div>
            <Footer />
        </div>
    );
};

export default JoinUs;