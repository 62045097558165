import {
  doc,
  getDoc,
  setDoc,
  updateDoc,
  increment,
  serverTimestamp,
} from "firebase/firestore";
import { db } from "../../firebase/FirebaseConfig";

const analyticsService = {
  // Initialize analytics doc for an activity
  async initializeAnalytics(activityId, businessName) {
    const analyticsRef = doc(db, "analytics", activityId);

    try {
      const analyticsDoc = await getDoc(analyticsRef);

      if (!analyticsDoc.exists()) {
        const initialData = {
          businessName,
          createdAt: serverTimestamp(),
          lastUpdated: serverTimestamp(),
          totals: {
            views: {
              total: 0,
              map: 0, // Marker view on map
              modal: 0, // Modal view after marker click
            },
            clicks: {
              total: 0,
              map: 0, // Marker clicks on map
            },
            contactActions: {
              total: 0,
              call: 0,
              whatsapp: 0,
              telegram: 0,
              email: 0,
            },
            specialOffer: {
              views: 0,
              clicks: 0,
              keyActions: {
                total: 0,
                call: 0,
                whatsapp: 0,
                telegram: 0,
                email: 0,
              },
            },
          },
          daily: {},
          monthly: {},
        };

        await setDoc(analyticsRef, initialData);
        console.log(
          "DBEUG! ---> Analytics initialized for activity:",
          activityId
        );
      }
    } catch (error) {
      console.error("Error initializign analytics:", error);
      throw error;
    }
  },

  // Track views (map marker OR modal)
  async trackMapMarkerOrModalView(activityId, source = "modal") {
    if (!["map", "modal"].includes(source)) {
      console.error("DEBUG! ---> Invalid view source:", source);
      return;
    }

    const analyticsRef = doc(db, "analytics", activityId);
    const today = new Date().toISOString().split("T")[0]; // YY-MM-DD format
    const currentMonth = today.substring(0, 7); // YY-MM format

    try {
      await updateDoc(analyticsRef, {
        [`totals.views.total`]: increment(1),
        [`totals.views.${source}`]: increment(1),
        [`daily.${today}.views.total`]: increment(1),
        [`daily.${today}.views.${source}`]: increment(1),
        [`monthly.${currentMonth}.views.total`]: increment(1),
        [`monthly.${currentMonth}.views.${source}`]: increment(1),
        lastUpdated: serverTimestamp(),
      });
    } catch (error) {
      console.error("Error tracking view:", error);
      throw error;
    }
  },

  // Track map marker clicks
  async trackMapMarkerClicks(activityId) {
    const analyticsRef = doc(db, "analytics", activityId);
    const today = new Date().toISOString().split("T")[0]; // YY-MM-DD format
    const currentMonth = today.substring(0, 7); // YY-MM format

    try {
      await updateDoc(analyticsRef, {
        "totals.clicks.total": increment(1),
        "totals.clicks.map": increment(1),
        [`daily.${today}.clicks.total`]: increment(1),
        [`daily.${today}.clicks.map`]: increment(1),
        [`monthly.${currentMonth}.clicks.total`]: increment(1),
        [`monthly.${currentMonth}.clicks.map`]: increment(1),
        lastUpdated: serverTimestamp(),
      });
    } catch (error) {
      console.error("Error tracking map marker click:", error);
      throw error;
    }
  },

  // Track special offer interactions
  async trackSpecialOffer(activityId, action = "view") {
    if (!["view", "click"].includes(action)) {
      console.error("DEBUG! ---> Invalid special offer action:", action);
      return;
    }

    const analyticsRef = doc(db, "analytics", activityId);
    const today = new Date().toISOString().split("T")[0]; // YY-MM-DD format
    const currentMonth = today.substring(0, 7); // YY-MM format

    try {
      await updateDoc(analyticsRef, {
        [`totals.specialOffer.${action}s`]: increment(1),
        [`daily.${today}.specialOffer.${action}s`]: increment(1),
        [`monthly.${currentMonth}.specialOffer.${action}s`]: increment(1),
        lastUpdated: serverTimestamp(),
      });
    } catch (error) {
      console.error("Error tracking special offer:", error);
      throw error;
    }
  },

  // Track key actions (from modal or special offer)
  async trackKeyAction(activityId, type, source = "modal") {
    if (!["call", "whatsapp", "telegram", "email"].includes(type)) {
      console.error("DEBUG! ---> Invalid key action type:", type);
      return;
    }

    if (!["modal", "specialOffer"].includes(source)) {
      console.error("DEBUG! ---> Invalid key action source:", source);
      return;
    }

    const analyticsRef = doc(db, "analytics", activityId);
    const today = new Date().toISOString().split("T")[0];
    const currentMonth = today.substring(0, 7);
    const basePath =
      source === "specialOffer" ? "specialOffer.keyActions" : "contactActions";

    try {
      await updateDoc(analyticsRef, {
        [`totals.${basePath}.total`]: increment(1),
        [`totals.${basePath}.${type}`]: increment(1),
        [`daily.${today}.${basePath}.total`]: increment(1),
        [`daily.${today}.${basePath}.${type}`]: increment(1),
        [`monthly.${currentMonth}.${basePath}.total`]: increment(1),
        [`monthly.${currentMonth}.${basePath}.${type}`]: increment(1),
        lastUpdated: serverTimestamp(),
      });
    } catch (error) {
      console.error("DEBUG! ---> Error tracking key action:", error);
      throw error;
    }
  },

  // Get analytics data with optional date range
  async getAnalytics(activityId, startDate = null, endDate = null) {
    const analyticsRef = doc(db, "analytics", activityId);

    try {
      const analyticsDoc = await getDoc(analyticsRef);
      if (!analyticsDoc.exists()) return null;

      const data = analyticsDoc.data();

      // If no date range specified, return all data
      if (!startDate || !endDate) return data;

      // Filter daily data for date range
      const filteredDaily = {};
      Object.entries(data.daily || {}).forEach(([date, stats]) => {
        if (date >= startDate && date <= endDate) {
          filteredDaily[date] = stats;
        }
      });

      return {
        ...data,
        daily: filteredDaily,
      };
    } catch (error) {
      console.error("DEBUG! ---> Error getting analytics:", error);
      throw error;
    }
  },

  // Get analytics summary for dashboard
  async getAnalyticsSummary(activityId) {
    try {
      const data = await this.getAnalytics(activityId);
      if (!data) return null;

      // Calculate CTR
      const mapCTR =
        data.totals.views.map > 0
          ? ((data.totals.clicks.map / data.totals.views.map) * 100).toFixed(1)
          : 0;

      return {
        views: {
          map: data.totals.views.map,
          modal: data.totals.views.modal,
          total: data.totals.views.total,
        },
        clicks: {
          map: data.totals.clicks.map,
          mapCTR: mapCTR,
        },
        contactActions: data.totals.contactActions,
        specialOffer: data.totals.specialOffer,
      };
    } catch (error) {
      console.error("DEBUG! ---> Error getting analytics summary:", error);
      throw error;
    }
  },
};

export default analyticsService;
