import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import './SectionNine.css';

const SectionNine = () => {
    const navigate = useNavigate();
    const options = [
        {
            number: 1,
            title: "Ничего не делать",
            description: "Оставить все, как есть. Переживать из-за неопределенности в делах. Продолжать тратить большие деньги на рекламу, которую не увидит Ваша ЦА.",
            className: "sn-option-gray"
        },
        {
            number: 2,
            title: "Присоединиться",
            description: "Сделать верный шаг, чтобы воспользоваться преимуществом новой платформы, получив первые заявки, отзывы и оценки, чтобы выделяться среди остальных.",
            className: "sn-option-purple"
        }
    ];

    const animatedTextRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    animatedTextRef.current?.classList.add('animate');
                }
            },
            { threshold: 0.5 }
        );

        if (animatedTextRef.current) {
            observer.observe(animatedTextRef.current);
        }

        return () => {
            if (animatedTextRef.current) {
                observer.unobserve(animatedTextRef.current);
            }
        };
    }, []);

    const animatedText = "есть 2 пути";

    return (
        <div className="section-nine">
            <h2 className="section-nine-title">
                Сейчас у вас <span ref={animatedTextRef} className="animated-text">
                        {animatedText.split('').map((char, index) => (
                            <span key={index} className={`animated-char${char === ' ' ? ' space' : ''}`} style={{animationDelay: `${index * 16}ms`}}>
                                {char === ' ' ? '\u00A0' : char}
                            </span>
                        ))}
                </span>
            </h2>
            <span className="section-nine-subtitle">
                Какой путь выберите именно Вы?
            </span>

            <div className="sn-options-container">
                {options.map((option, index) => (
                <div key={index} className={`sn-option ${option.className}`}>
                    <div className="sn-option-number-container">
                        <h2 className="sn-option-number">{option.number}</h2>
                        <p className="sn-option-number-text">Путь</p>
                    </div>
                    <div className="sn-option-content">
                        <h3 className="sn-option-title">{option.title}</h3>
                        <p className="sn-option-description">{option.description}</p> 
                    </div>    
                </div>           
                ))}
            </div>
            <button className="sn-button" onClick={() => navigate('/activity-provider/onboarding/step1')}>Присоединиться</button>

        </div>
    );
};

export default SectionNine;