import React from "react";
import "./SectionSix.css";

const SectionSix = ({ logoUrls }) => {

    return (
        <div className="section-six">
            <h2 className="section-six-title">
                Вы в хорошей кампании
            </h2>
            <span className="section-six-subtitle"> 
                Более 100 различных организаций, ИП и Самозанятых уже проявили заинтересованность в размещении. Наверняка, некоторых из них Вы уже знаете.
            </span>
            <div className="activity-providers-logo-grid">
                {logoUrls.map((url, index) => (
                    <div key={index} className="apl-wrapper">
                        <img src={url} alt={`Activity Provider logo ${index + 1}`} />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default SectionSix;