import React, { createContext, useState, useEffect } from 'react';
import { auth, db } from '../firebase/FirebaseConfig';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [userRole, setUserRole] = useState(null);
    const [loading, setLoading] = useState(true);

    const logout = async () => {
        try {
            await signOut(auth);
            setUser(null);
            setUserRole(null);
        } catch (error) {
        }
    }
        
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (user) {
                setUser(user);

                    // Fetch user role
                    const providerDoc = await getDoc(doc(db, 'activityProviders', user.uid));
                    if (providerDoc.exists()) {
                        setUserRole('activityProvider');
                    } else {
                    // If not an activity provider, check if admin
                    const adminDoc = await getDoc(doc(db, 'admins', user.uid));
                    if (adminDoc.exists()) {
                        setUserRole('admin');
                    } else {
                    // If neither activity provider nor admin, logout
                    setUserRole(null);
                    await logout();
                    }
                }
        } else {
            setUser(null);
            setUserRole(null);
        }
            setLoading(false);
        });

        return () => unsubscribe();
    }, []);

    return (
        <AuthContext.Provider value={{ user, userRole, loading, logout }}>
            {children}
        </AuthContext.Provider>
    );
};