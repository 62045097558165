import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { OnboardingProvider } from '../../../context/OnboardingContext';
import OnboardingStepOne from './OnboardingStepOne/OnboardingStepOne';
import OnboardingStepTwo from './OnboardingStepTwo/OnboardingStepTwo';
import OnboardingSuccess from './OnboardingSuccess/OnboardingSuccess';

const OnboardingRoutes = () => {
    return (
        <OnboardingProvider>
            <Routes>
                <Route path="step1" Component={OnboardingStepOne} />
                <Route path="step2" Component={OnboardingStepTwo} />
                <Route path="success" Component={OnboardingSuccess} />
            </Routes>
        </OnboardingProvider>
    );
};

export default OnboardingRoutes;