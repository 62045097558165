import React from 'react';
import './Gallery.css';

const Gallery = ({ images, currentIndex }) => {
  return (
    <div className="gallery">
      <div className="gallery-container">
        <img src={images[currentIndex]} alt={`Slide ${currentIndex + 1}`} className="gallery-image" />
      </div>
    </div>
  );
};

export default Gallery;
