const CitySelector = ({ selectedCity, value, onChange, className }) => (
    <select
      value={selectedCity || value || 'Новосибирск'}
      onChange={onChange}
      className={className}
    >
      {/* <option value=''>Выберите город</option> */}
      <option value='Новосибирск'>Новосибирск</option>
      <option value='Бердск'>Бердск</option>
      <option value='Академгородок'>Академгородок</option>
      <option value='Искитим'>Искитим</option>
    </select>
  );

export default CitySelector;
  