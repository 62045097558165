import React, { useRef, useEffect } from 'react';
import { FaRegClock, FaRegCalendarCheck } from 'react-icons/fa';
import './Pricing.css';

const PricingBlock = ({ pricing }) => {
    const carouselRef = useRef(null);

    useEffect(() => {
        if (carouselRef.current) {
            const carousel = carouselRef.current;
            let isDown = false;
            let startX;
            let scrollLeft;

            const onMouseDown = (e) => {
                isDown = true;
                startX = e.pageX - carousel.offsetLeft;
                scrollLeft = carousel.scrollLeft;
                carousel.style.cursor = 'grabbing';
            };

            const onMouseLeave = () => {
                isDown = false;
                carousel.style.cursor = 'grab';
            };

            const onMouseUp = () => {
                isDown = false;
                carousel.style.cursor = 'grab';
            };

            const onMouseMove = (e) => {
                if (!isDown) return;
                e.preventDefault();
                const x = e.pageX - carousel.offsetLeft;
                const walk = (x - startX) * 2;
                carousel.scrollLeft = scrollLeft - walk;
            };

            carousel.addEventListener('mousedown', onMouseDown);
            carousel.addEventListener('mouseleave', onMouseLeave);
            carousel.addEventListener('mouseup', onMouseUp);
            carousel.addEventListener('mousemove', onMouseMove);

            return () => {
                carousel.removeEventListener('mousedown', onMouseDown);
                carousel.removeEventListener('mouseleave', onMouseLeave);
                carousel.removeEventListener('mouseup', onMouseUp);
                carousel.removeEventListener('mousemove', onMouseMove);
            };
        }
    }, []);

    if (!pricing || pricing.length === 0) {
        return null;
    }

    const renderPricingCard = (option, index) => (
        <div key={index} className="pricing-card">
            <div className="pricing-field1">{option.field1}</div>
            <div className="pricing-field3">₽ {option.field3}</div>
            <div className="pricing-field2">{option.field2}</div>
            <div className="pricing-field4"><FaRegClock className="pf4-icon-item"/>{option.field4}</div>
            <div className="pricing-field5"><FaRegCalendarCheck className="pf5-icon-item"/>{option.field5}</div>
        </div>
    );

    const hasFewCards = pricing.length < 4;

    return (
        <div className="pricing-container">
            <div 
                ref={carouselRef} 
                className={`pricing-carousel ${hasFewCards ? 'fewer-cards' : ''}`}
            >
                {pricing.map(renderPricingCard)}
            </div>
        </div>
    );
};

export default PricingBlock;