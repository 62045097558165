import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { db, storage } from '../../../../firebase/FirebaseConfig';
import { collection, addDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'
import '../../../global/QuillCustomStyles.css';
import './AddFairytaleForm.css';

const AddFairytaleForm = () => {
    const [fairytaleData, setFairytaleData] = useState({
        image: null,
        title: '',
        slug: '',
        annotation: '',
        content: '',
        date: ''
    });
    const [preview, setPreview] = useState({ image: null });
    const [error, setError] = useState('');
    const [loading, setLoading]= useState(false);
    const navigate = useNavigate();

    const quillModules = {
        toolbar: {
          container: [
            [{ 'header': [1, 2, 3, false] }],
            ['bold', 'italic', 'underline', 'strike'],
            [{'list': 'ordered'}, {'list': 'bullet'}],
            [{ 'script': 'sub'}, { 'script': 'super' }],
            [{ 'indent': '-1'}, { 'indent': '+1' }],
            [{ 'direction': 'rtl' }],
            [{ 'color': ['#f21170', '#72147e', '#35183c', '#323232', 'white'] }, { 'background': ['#f21170', '#72147e', '#35183c', '#323232', 'white'] }],
            [{ 'align': [] }],
            ['link', 'image', 'video', 'blockquote'],
            ['clean']
          ],
        }
      };

    const handleChange = (e) => {
        const { name, value, files } = e.target;
        if (name === 'image') {
            if (files && files[0]) {
                setFairytaleData(prev => ({ ...prev, [name]: files[0] }));
                setPreview(prev => ({ ...prev, [name]: URL.createObjectURL(files[0]) }));
            }
        } else if ( name === 'content') {
            //THIS will be handled by Quill
        } else {
            setFairytaleData(prev => ({ ...prev, [name]: value} ));
        }
    };

    const handleContentChange = (content) => {
        setFairytaleData(prev => ({ ...prev, content }));
      };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setLoading(true);

        try {
            let imageUrl = null;
            if (fairytaleData.image) {
                const imageRef = ref(storage, `fairytale_images/${Date.now()}_${fairytaleData.image.name}`);
                await uploadBytes(imageRef, fairytaleData.image);
                imageUrl = await getDownloadURL(imageRef);
            }

            const fairytaleRef = await addDoc(collection(db, 'fairytales'), {
                title: fairytaleData.title,
                slug: fairytaleData.slug,
                annotation: fairytaleData.annotation,
                content: fairytaleData.content,
                date: fairytaleData.date,
                image: imageUrl,
                createdAt: new Date()
            });

            navigate('/admin/fairytales-list');
        } catch (error) {
            console.error("DEBUG: ---> Error adding fairytale:", error)
            setError('Ошибка! Не удалось загрузить сказку');
        } finally {
            setLoading(false);
        }
    };

    const handleImageClick = (inputId) => {
        document.getElementById(inputId).click();
    };

    return (
        <div className="add-fairytale-form-container">
            <h2>Добавить новую сказку</h2>
            {error && <div className="error-message">{error}</div>}
            <form onSubmit={handleSubmit} className="add-fairytale-form">
                <div className="form-group">
                    <label htmlFor="image">Изображение сказки</label>
                    <div 
                        className="aff-image-upload-area"
                        onClick={() => handleImageClick('image')}
                    >
                        {preview.image ? (
                            <img src={preview.image} alt="Fairytale preview" className="aff-image-preview" />
                        ) : (
                            <div className="aff-upload-placeholder">
                                <span>Нажмите, чтобы загрузить изображение</span>
                            </div>
                        )}
                    </div>
                    <input
                        type="file"
                        id="image"
                        name="image"
                        onChange={handleChange}
                        accept="image/*"
                        style={{ display: 'none' }}
                    />
                </div>

                <div className="form-group">
                    <label htmlFor="title">Заголовок</label>
                         <input
                            type="text"
                            id="title"
                            name="title"
                            value={fairytaleData.title}
                            onChange={handleChange}
                            placeholder="Введите заголовок сказки"                               
                            required
                        />
                </div>

                <div className="form-group">
                    <label htmlFor="slug">Транслитерация URL</label>
                        <input
                            type="text"
                            id="slug"
                            name="slug"
                            value={fairytaleData.slug}
                            onChange={handleChange}
                            placeholder="nazvanie-zagolovka-skazki"
                            required
                        />
                 </div>

                <div className="form-group">
                    <label htmlFor="title">Аннотация</label>
                        <textarea
                            id="annotation"
                            name="annotation"
                            value={fairytaleData.annotation}
                            onChange={handleChange}
                            rows="3"
                            placeholder="Краткое описание сказки"
                            required
                        />
                </div>

                <div className="form-group">
                    <label htmlFor="content">Текст Сказки</label>
                        <ReactQuill
                            value={fairytaleData.content}
                            onChange={handleContentChange}
                            modules={quillModules}
                            theme="snow"
                />
                </div>

                <div className="form-group">
                    <label htmlFor="date">Дата публикации</label>
                    <input
                        type="date"
                        id="date"
                        name="date"
                        value={fairytaleData.date}
                        onChange={handleChange}
                        required
                    />
                </div>

                <button type="submit" className="submit-button" disabled={loading}>
                        {loading ? 'Сохранение' : 'Сохранить'}
                </button>
            </form>
        </div>
    );
};

export default AddFairytaleForm;