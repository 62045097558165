import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './NotFound.css';

const NotFound = () => {
    const location = useLocation();

    useEffect(() => {
        const title = document.querySelector('title');
        title.textContent = '404: Not Found';
    }, []);

    return (
        <div className="not-found-page">
            <h1>404</h1>
            <h2>Страница не найдена</h2>
            <p>Ой, запрашиваемая Вами страница "{location.pathname}" не существует.</p>
            {/* Add links to homepage, signup or other relevant pages */}
        </div>
    );
};

export default NotFound;