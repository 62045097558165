import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, db } from '../../../firebase/FirebaseConfig';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import './AdminLogin.css';

const AdminLogin = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setLoading(true);

        try {
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;

            // Check if the user is an admin
            const adminDoc = await getDoc(doc(db, 'admins', user.uid));

            if (adminDoc.exists()) {
                navigate('/admin');
            } else {
                setError('У Вас нет права доступа к панели Администратора!');
                // Sign out the user if they are not an admin
                await auth.signOut();
            }
        } catch (error) {
            setError('Неправильное "имя пользователя" или "пароль"');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="admin-login">
            <h2>Войти в систему</h2>
            {error && <p className="error">{error}</p>}
            <form onSubmit={handleSubmit}>
                <div className="admin-login-input-group">
                    <input
                        type="email"
                        id="email"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </div>
                <div className="admin-login-input-group">
                    <input
                        type="password"
                        id="password"
                        placeholder="Пароль"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                </div>
                <button type="submit" disabled={loading}>{loading ? 'Загрузка...' : 'Войти'}</button>
            </form>
        </div>
    );
};

export default AdminLogin;