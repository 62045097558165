import React, { createContext, useState, useEffect } from 'react';

export const OnboardingContext = createContext();

export const OnboardingProvider = ({ children }) => {
    const [onboardingData, setOnboardingData] = useState(() => {
        const savedData = localStorage.getItem('onboardingData');
        return savedData ? JSON.parse(savedData) : {};
    });

    useEffect(() => {
        localStorage.setItem('onboardingData', JSON.stringify(onboardingData));
    }, [onboardingData]);

    useEffect(() => {
        const savedData = localStorage.getItem('onboardingData');
        if (savedData) {
            const data = JSON.parse(savedData);
            if (data.tempData && !data.activityId) { // THIS checks if registration is incomplete
                const sixHoursAgo = new Date(Date.now() - 6 * 60 * 60 * 1000);
                const savedTime = new Date(data.timestamp || Date.now());

                if (savedTime < sixHoursAgo) {
                    localStorage.removeItem('onboardingData');
                    setOnboardingData({});
                }
            }
        }
    }, []);

    return (
       <OnboardingContext.Provider value={{ onboardingData, setOnboardingData }}>
            {children}
       </OnboardingContext.Provider>
    );
};

