import React from "react";
import './SectionTwo.css';

const SectionTwo = () => {
    return (
        <div className="section-two">
            <img src ="/general_images/для-детей-ру-длядедетй.ру-ddetey.ru-главное-изображение.jpg" alt="ДляДетей.ру" />
        </div>
    )
};

export default SectionTwo;