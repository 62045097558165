import React from "react";
import './SectionThree.css';

const SectionThree = () => {
    return (
        <div className="section-three">
            <h2 className="section-three-title">
                    Что Вы получите, если разместите объявление на карте
            </h2>
                <div className="section-three-benefits">
                    <div className="stb-usp-1">
                        <span className="number-circle">1</span>
                        <p className="stb-usp-1-p">Родители сразу увидят Вашу локацию на карте</p>
                    </div>
                    <div className="stb-usp-2">
                        <span className="number-circle">2</span>
                        <p className="stb-usp-2-p">Сможете сократить затраты на рекламу и маркетинг</p>
                    </div>
                    <div className="stb-usp-3">
                    <span className="number-circle">3</span>
                        <p className="stb-usp-3-p">Родители смогут с Вами связаться, нажав 1 кнопку</p>
                    </div>
                    <div className="stb-usp-4">
                        <span className="number-circle">4</span>
                        <p className="stb-usp-4-p">Ваши локации отобразятся и в других городах</p>
                    </div>
                    <div className="stb-usp-5">
                        <span className="number-circle">5</span>
                        <p className="stb-usp-5-p">Удобную и понятную аналитку для принятия решений</p>
                    </div> 
                    <div className="stb-usp-6">
                        <span className="number-circle">6</span>
                        <p className="stb-usp-6-p">...и много других возможностей для Вашего бизнеса</p>
                        
                    </div>                
                </div>
        </div>
    )
};

export default SectionThree;