import React from "react";
import { useNavigate } from "react-router-dom";
import { FaRegSquareCheck } from "react-icons/fa6";
import './SectionFive.css';

const SectionFive = () => {
    const features = [
        { category: "Функционал", items: [
        { name: "Отображение Вашей локации на карте", featureIsAvailable: true },
        { name: "Заявки поступают в WhatsApp, ТГ", featureIsAvailable: true },
        { name: "Личный кабинет", featureIsAvailable: true },
        { name: "Одно главное объявление", featureIsAvailable: true },
        { name: "Ваша контактная информация", featureIsAvailable: true },
        { name: "Описание групп", featureIsAvailable: true },
        { name: "Расписание занятий", featureIsAvailable: true },
        { name: "Адреса филиалов", featureIsAvailable: true },
        { name: "Фото галерея", featureIsAvailable: true },
        { name: "Отзывы родителей", featureIsAvailable: true },
        { name: "Стоимость занятий / услуг", featureIsAvailable: true },
        { name: "Аналитка Показов, Кликов, КД и CTR", featureIsAvailable: true },
        { name: "Специальные Предложения", featureIsAvailable: true }
    ]},
    { category: "Скоро", items: [        
        { name: "Внутреннее продвижение", featureIsAvailable: true },
        { name: "Рекламный кабинет", featureIsAvailable: true }
    ]},
];

const navigate = useNavigate(true);

    return (
        <div className="section-five">
            <h2 className="section-five-title">
                от хобби до <span style={{color: "#f22170" }}>империи</span>:<br/>ваш путь начинается здесь
            </h2>
            <span className="section-five-subtitle"> 
                Мы разрабатываем набор инструментов, чтобы Вы могли привлекать внимание родителей 
            </span>

            <div className="sf-features-container">
                <table className="sf-features-table">
                    <tbody>
                    {features.map((category, categoryIndex) => (
                        <React.Fragment key={`category-${categoryIndex}`}>
                            <tr className="sf-category-row">
                                <td colSpan="3">{category.category}</td>
                            </tr>
                            {category.items.map((item, itemIndex) => (
                                <tr key={`${categoryIndex}-${itemIndex}`}>
                                    <td>{item.name}</td>
                                    <td>{item.featureIsAvailable && <FaRegSquareCheck className="sf-check" />}</td>
                                </tr>
                            ))}
                        </React.Fragment>
                    ))}
                    </tbody>
                </table> 
            </div> 
            <button className="sf-button" onClick={() => navigate('/activity-provider/onboarding/step1')}>Присоединиться</button>            
        </div>
    );
};

export default SectionFive;