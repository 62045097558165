import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../../../../firebase/FirebaseConfig';
import { fetchSignInMethodsForEmail, onAuthStateChanged } from 'firebase/auth';
import { OnboardingContext } from '../../../../context/OnboardingContext';

import './OnboardingStepOne.css';

const OnboardingStepOne = () => {
    const { onboardingData, setOnboardingData } = useContext(OnboardingContext);
    const [firstName, setFirstName] = useState(onboardingData.firstName || '');
    const [email, setEmail] = useState(onboardingData.email || '');
    const [password, setPassword] = useState('')
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

const checkIfEmailInUse = async (email) => {
    try {
        const signInMethods = await fetchSignInMethodsForEmail(auth, email);
        return signInMethods.length > 0;
    } catch (error) {
        console.error("DEBUG: --> Error checking email:", error);
        return false;
    }
};

const validateEmail = (email) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
};

const validatePassword = (password) => {
    const minLength = password.length >= 8;
    const hasLowercase = /[a-z]/.test(password);
    const hasUppercase = /[A-Z]/.test(password);
    const hasDigit = /\d/.test(password);
    const hasSpecialChar = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>/?]/.test(password);
    const conditionsMet = [hasLowercase,hasUppercase, hasDigit, hasSpecialChar].filter(Boolean).length; 
    
    return minLength &&  conditionsMet >= 3;
}

useEffect(() => {
    setError('');
}, [firstName, email, password]);

useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
        if (user) {
            // If user is already logged in, redirect to dashboard
            navigate('/activity-provider/dashboard');
        }
    });
    return () => unsubscribe();
}, [navigate]);

const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    if (!validateEmail(email)) {
        setError('Пожалуйста, введите правильный email адрес');
        return;
    }

    if (!validatePassword(password)) {
        setError('Пароль должен содержать, минимум, 8 символов, в которых присутствует, как минимум, 1 цифра, 1 заглавная буква и 1 уникальный символ, например, #');
        return;
    }

    setLoading(true);

    try {

        const emailInUse = await checkIfEmailInUse(email);
        if (emailInUse) {
            setError('emailInUse: Этот email уже зарегистрирован. Пожалуйста, используйте другой email для регистрации.');
            setLoading(false);
            return;
        }

        const tempData = {
            firstName,
            email,
            password,
            tempData: true,
            timestamp: Date.now()
        };

        // Instead of creating user account immediately on step 1, we will store name, email and password temporarily...
        setOnboardingData(tempData);
        localStorage.setItem('onboardingData', JSON.stringify(tempData));

        setLoading(false);
        navigate('/activity-provider/onboarding/step2')
    } catch (error) {
        console.error("DEBUG: --> Signup error:", error.code, error.message);
        let errorMessage = `Failed to create an account: ${error.message}`;
        if (error.code === 'auth/email-already-in-use') {
            errorMessage = 'Этот email уже зарегистрирован. Пожалуйста, используйте другой email для регистрации.'
        }
        setError(errorMessage);
        setLoading(false);
    }
};

const usps = [
    "• Получайте новые лиды на автопилоте",
    "• Станьте заметными для родителей",
    "• Масштабируйтесь в другие города",
    "• Экономьте на маркетинге и продвижении"
];

    return (
        <div className="activity-provider-signup">
            <div className="ap-usp-container">
            <h1><span style={{ cursor: 'pointer '}} onClick={() => navigate('/')}>ДляДетей.ру</span> это интерактивная карта для поиска детских занятий и услуг в вашем городе.</h1>
                <ul>
                    {usps.map((usp, index) =>(
                        <li key={index}>{usp}</li>
                    ))}
                </ul>
                <p><span onClick={() => navigate('/terms-of-service')}>Правила Пользования </span> | <span onClick={() => navigate('/privacy-policy')}>Политика Конфиденциальности</span></p>
            </div>
            <div className="ap-signup-form-container">
                <h2>Создать аккаунт</h2>
                {error && <p className="ap-error">{error}</p>}
                <form onSubmit={handleSubmit}>
                    <div className="ap-form-group">
                        <input 
                            type="text"
                            id="firstName"
                            placeholder="Как Вас зовут?"
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                            required
                        />
                    </div>
                    <div className="ap-form-group">
                        <input 
                            type="email"
                            id="email"
                            placeholder="Ваш email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </div>
                    <div className="ap-form-group">
                        <input 
                            type="password"
                            id="password"
                            placeholder="пароль из не менее 8 символов"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                    </div>
                    <button type="submit" disabled={loading}>
                        {loading ? 'Создаю аккаунт...' : 'Создать аккаунт'}
                    </button>
                </form>
                <p className="ap-login-link">
                    Уже есть аккаунт? <span onClick={() => navigate('/activity-provider/login')}>Войти</span>
                </p>
            </div>
        {/* MOBILE */}
        <div className="ap-mobile-layout">
                <div className="ap-mobile-usp-container">
                <h1><span onClick={() => navigate('/')}>ДляДетей.ру</span> это интерактивная карта для поиска детских занятий и услуг в вашем городе.</h1>
                    <ul>
                        {usps.map((usp, index) => (
                            <li key={index}>{usp}</li>
                        ))}
                    </ul>
                </div>
                <div className="ap-mobile-signup-form-container">
                    <h2>Создать аккаунт</h2>
                    {error && <p className="ap-error">{error}</p>}
                    <form onSubmit={handleSubmit}>
                        <div className="ap-mobile-form-group">
                            <input
                                type="text"
                                id="firstName"
                                placeholder="Ваше имя"
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                                required
                            />
                        </div>
                        <div className="ap-mobile-form-group">
                            <input
                                type="email"
                                id="email"
                                placeholder="Ваш email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                        </div>
                        <div className="ap-mobile-form-group">
                            <input
                                type="password"
                                id="password"
                                placeholder="пароль из не менее 8 символов"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                        </div>
                        <button type="submit" disabled={loading}>
                            {loading ? 'Создаю аккаунт...' : 'Создать аккаунт'}
                        </button>
                    </form>
                    <p className="ap-mobile-login-link">
                        Уже есть аккаунт? <span onClick={() => navigate('/activity-provider/login')}>Войти</span>
                    </p>
                </div>
                <p><span onClick={() => navigate('/terms-of-service')}>Правила Пользования </span> | <span onClick={() => navigate('/privacy-policy')}>Политика Конфиденциальности</span></p>
            </div>
        </div>
    );
};

export default OnboardingStepOne;