const pointInPolygon = (point, polygon) => {
    let inside = false;
    for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
        const xi = polygon[i][0], yi = polygon[i][1];
        const xj = polygon[j][0], yj = polygon[j][1];
        const intersect = ((yi > point[1]) !== (yj > point[1])) &&
            (point[0] < (xj - xi) * (point[1] - yi) / (yj - yi) + xi);
        if (intersect) inside = !inside;
    }
    return inside;
};

// THIS checks if coordinates are in any active service area (isInTerritory)
export const checkLocation = (lat, lng) => {
    try {
        const territory = require('../data/territories.json');
        // Checks against each city's polygon and returns 'true' if the point is in ANY of the cities
        return territory.features.some(feature => {
            const coords = feature.geometry.coordinates[0];
            const isInCity = pointInPolygon([lng, lat], coords);
            // ONLY return 'true' if both conditions are met
            return isInCity && feature.properties.isActive === true;
        });
    } catch (error) {
        console.error("DEBUG! ---> Error in checkLocation:", error);
        return false;
    }    
};

// THIS func detects user's city from coords
export const detectUserCity = (lat, lng) => {
    try {
        const territory = require('../data/territories.json');
        const cityFeature = territory.features.find(feature => {
            const coords = feature.geometry.coordinates[0];
            return pointInPolygon([lng, lat], coords);
        });
        return cityFeature?.properties.displayName || 'Новосибирск';
    } catch (error) {
        console.error("DEBUG! ---> Error detecting user city:", error);
        return 'Новосибирск'
    }
};