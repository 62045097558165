import React from 'react';
import './CTABlock.css';

const CTABlock = ({ ctaBlock }) => {
  return (
    <div className="cta-block">
      <h2>Записать ребенка!</h2>
      <p>{ctaBlock}</p>
      <button className="cta-button">Записаться</button>
    </div>
  );
};

export default CTABlock;
