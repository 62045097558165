import React from 'react';
import { BrowserRouter, Navigate, Route, Routes, useParams } from "react-router-dom";
import { AuthProvider } from './context/AuthContext';
import MapHome from './components/pages/MapHome/MapHome';
import SkazkiPage from './components/pages/Skazki/SkazkiPage';
import Skazka from './components/pages/Skazki/Skazka';
import ActivityProviderPage from './components/pages/ActivityProviderPage/ActivityProviderPage';
import BlogPage from './components/pages/Blog/BlogPage';
import Blog from './components/pages/Blog/Blog';
import JoinUs from './components/pages/JoinUs/JoinUs';
import AdminLogin from './components/admin/AdminLogin/AdminLogin';
import AdminPanel from './components/admin/AdminPanel/AdminPanel';
import ActivityProvidersList from './components/admin/ActivityProviders/ActivityProvidersList/ActivityProvidersList';
// import ActivityFormComponents from './components/activity_providers/ActivityProviderPanel/ActivityFormComponents/ActivityFormComponents';
import EditActivityForm from './components/activity_providers/ActivityProviderPanel/EditActivityForm/EditActivityForm';
import BlogList from './components/admin/Blogs/BlogList/BlogList';
import AddBlogForm from './components/admin/Blogs/AddBlogForm/AddBlogForm';
import EditBlogForm from './components/admin/Blogs/EditBlogForm/EditBlogForm';
import FairytalesList from './components/admin/Fairytales/FairytalesList/FairytalesList';
import AddFairytaleForm from './components/admin/Fairytales/AddFairytaleForm/AddFairytaleForm';
import EditFairytaleForm from './components/admin/Fairytales/EditFairytaleForm/EditFairytaleForm';
import ActivityProviderPanel from './components/activity_providers/ActivityProviderPanel/ActivityProviderPanel';
import OnboardingRoutes from './components/activity_providers/Onboarding/OnboardingRoutes';
import ActivityProviderDashboard from './components/activity_providers/ActivityProviderPanel/ActivityProviderDashboard/ActivityProviderDashboard';
import ActivityProviderLogIn from './components/activity_providers/ActivityProviderLogIn/ActivityProviderLogIn';
import ActivityProviderSettings from './components/activity_providers/ActivityProviderPanel/ActivityProviderSettings/ActivityProviderSettings';
import NotFound from './components/global/404/NotFound';
import AddTermsForm from './components/admin/TermsOfService/AddTermsForm/AddTermsForm';
import TermsOfServicePage from './components/pages/TermsOfServicePage/TermsOfServicePage';
import AddPrivacyForm from './components/admin/PrivacyPolicy/AddPrivacyForm/AddPrivacyForm';
import PrivacyPolicyPage from './components/pages/PrivacyPolicyPage/PrivacyPolicyPage';
import ActivityProviderSpecialOffers from './components/activity_providers/ActivityProviderPanel/ActivityProviderSpecialOffers/ActivityProviderSpecialOffers';
import ActivityProviderAddSpecialOffer from './components/activity_providers/ActivityProviderPanel/ActivityProviderSpecialOffers/ActivityProviderAddSpecialOffer/ActivityProviderAddSpecialOffer';
import ActivityProviderEditSpecialOffer from './components/activity_providers/ActivityProviderPanel/ActivityProviderSpecialOffers/ActivityProviderEditSpecialOffer/ActivityProviderEditSpecialOffer';

// const ProtectedRoute = ({ children }) => {
//     const isLoggedIn = localStorage.getItem('adminLoggedIn') === 'true';
//     return isLoggedIn ? children : <Navigate to="/admin/login" />;
// };


const AppRoutes = () => {
    return (
        <AuthProvider>
            <BrowserRouter>
                <Routes>
                    {/* Public routes */}
                    <Route path="/" Component={MapHome} />
                    <Route path="/skazki" Component={SkazkiPage} />
                    <Route path="/skazki/:skazkaId/:slug" Component={Skazka} />
                    <Route 
                        path="/activities/:category/:activityId" 
                        element={<MapHome initialActivityId={useParams().activityId} />}
                    />
                    <Route path="/blog" Component={BlogPage} />
                    <Route path="/blog/:blogId/:slug" Component={Blog} />
                    <Route path="/join-us" Component={JoinUs} />
                    <Route path="/terms-of-service" Component={TermsOfServicePage} />
                    <Route path="/privacy-policy" Component={PrivacyPolicyPage} />

                    {/* Admin routes */}
                    <Route path="/admin/login" Component={AdminLogin} />
                    <Route path="/admin" Component={AdminPanel}>
                        <Route index Component={() => <Navigate to="/admin/activity-providers" replace />} />
                        <Route path="activity-providers" Component={ActivityProvidersList} />
                        <Route path="blog-list" Component={BlogList} />
                        <Route path="blog-list/add-blog" Component={AddBlogForm} />
                        <Route path="blog-list/:blogId/edit" Component={EditBlogForm} />
                        <Route path="fairytales-list" Component={FairytalesList} />
                        <Route path="fairytales-list/add-fairytale" Component={AddFairytaleForm} />
                        <Route path="fairytales-list/:fairytaleId/edit" Component={EditFairytaleForm} />
                        <Route path="terms-of-service" Component={AddTermsForm} />
                        <Route path="terms-of-service/:termId" Component={AddTermsForm} />
                        <Route path="privacy-policy" Component={AddPrivacyForm} />
                        <Route path="privacy-policy/:privacyId" Component={AddPrivacyForm} />
                    </Route>

                    {/* ActivityProvider routes */}
                    <Route path="/activity-provider/login" Component={ActivityProviderLogIn} />
                    <Route path="/activity-provider/onboarding/*" Component={OnboardingRoutes} />

                    <Route path="/activity-provider" Component={ActivityProviderPanel}>
                        <Route index Component={() => <Navigate to="/activity-provider/dashboard" replace />} /> 
                        <Route path="dashboard" Component={ActivityProviderDashboard} /> 
                        {/* <Route path="add-activity" Component={ActivityFormComponents} /> */}
                        <Route path=":activityId/edit" Component={EditActivityForm} />
                        <Route path="special-offers-list" Component={ActivityProviderSpecialOffers} />
                        <Route path="special-offers-list/add-special-offer" Component={ActivityProviderAddSpecialOffer} />
                        <Route path="special-offers-list/:specialOfferId/edit" Component={ActivityProviderEditSpecialOffer} />
                        <Route path="account-settings" Component={ActivityProviderSettings} />
                    </Route>
                   
                    {/* Catch unknown routes within /activity-provider/ */}
                    <Route path="/activity-provider/*" Component={NotFound} />

                    {/* Catch-all for root-level routes */}
                    <Route path="*" Component={NotFound} />
   
                </Routes>
            </BrowserRouter>
        </AuthProvider>
        
    );
};

export default AppRoutes;