import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { collection, query, orderBy, getDocs, limit } from "firebase/firestore";
import { db } from "../../../firebase/FirebaseConfig";
import Header from "../../global/Header/Header";
import Footer from "../../global/Footer/Footer";
import SEO from "../../global/SEO";
import './TermsOfServicePage.css';

const TermsOfServicePage = () => {
  const [termsData, setTermsData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const fetchTermsFromDB = async () => {
      try {
        const termsRef = collection(db, 'terms');
        const q = query(termsRef, orderBy('createdAt', 'desc'), limit(1));
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
          const termsDoc = querySnapshot.docs[0];
          setTermsData({
            id: termsDoc.id,
            ...termsDoc.data()
          });
        } else {
          setError('Условия использования не найдены');
        }
      } catch (error) {
        console.error("DEBUG: ---> Error fetching terms:", error);
        setError('Ошибка! Невозможно загрузить условия использования');
      } finally {
        setLoading(false);
      }
    }

    fetchTermsFromDB();
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('ru-RU', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    }).replace(/\//g, '.');
  };

  const schemaMarkup = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "name": "Условия использования ДляДетей.ру",
    "description": "Условия использования сервиса ДляДетей.ру",
    "url": "https://ddetey.ru/terms-of-service"
  };

  if (loading) return <div>Загружаю...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div>
      <SEO
        title="Условия использования ДляДетей.ру"
        description="Ознакомьтесь с условиями использования сервиса ДляДетей.ру"
        keywords={['условия использования', 'правила пользования', 'ДляДетей.ру']}
        image={"/general_images/для-детей-ру-главное-изображение.png"}
        url="https://ddetey.ru/terms-of-service"
        ogType="website"
        schemaMarkup={schemaMarkup}
        canonical={`https://ddetey.ru${location.pathname}`}
      />
      <Header />
      <div className="terms-of-service-container">
        <h1 className="tos-title">{termsData.title}</h1>
        <p className="tos-last-updated">Последнее обновление: {formatDate(termsData.lastUpdated)}</p>
        <div className="tos-content" dangerouslySetInnerHTML={{ __html: termsData.content }}></div>
      </div>
      <Footer />
    </div>
  );
};

export default TermsOfServicePage;