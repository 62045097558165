import React, { useEffect, useState, useRef } from "react";
import { useLocation, useParams } from "react-router-dom";
import { geocodeAddress } from "../../mapbox/GeocodeAddress";
import { checkLocation, detectUserCity } from "../../../utils/geo";
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../../../firebase/FirebaseConfig";
import { createRoot } from "react-dom/client";
import { FaTimes, FaInfoCircle } from "react-icons/fa";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import Header from "../../global/Header/Header";
import analyticsService from "../../analytics/AnalyticsService";
import ActivityMarker from "../ActivityMarker/ActivityMarker";
import ActivityCardTestimonialsModal from "../ActivityCardTestimonialsModal/ActivityCardTestimonialsModal"
import ActivityProviderPage from "../ActivityProviderPage/ActivityProviderPage";
import CategoryBar from "../CategoryBar/CategoryBar";
import categoryData from "../../../data/categoryData";
import CitySelector from "../../global/CitySelector";
import SEO from "../../global/SEO";
import { allKeywords } from "../../global/seoKeywords";
import "./MapHome.css";
import "../../../App.css"

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

// Calculates distance between activities and user geolocation
// function getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
//     const R = 6371; // Radius of the earth in km
//     const dLat = deg2rad(lat2 - lat1);
//     const dLon = deg2rad(lon2 - lon1);
//     const a =
//         Math.sin(dLat/2) * Math.sin(dLat/2) +
//         Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
//         Math.sin(dLon/2) * Math.sin(dLon/2)
//     ;
//     const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
//     const d = R * c; // Distance in km
//     return d;
// }

// function deg2rad(deg) {
//     return deg * (Math.PI/180)
// }

// const NOVOSIBIRSK_CENTER = [82.9346, 55.0415];
// const territoryRadius = getDistanceFromLatLonInKm(
//   NOVOSIBIRSK_CENTER[1], // lat
//   NOVOSIBIRSK_CENTER[0], // lon
//   56.5, // North boundary
//   84.5 // East Boundary
// );

// console.log("TERRITORY RADIUS:", Math.round(territoryRadius), 'km from city center');

const MapHome = () => {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [isPageLoaded, setIsPageLoaded] = useState(false);
  const [locationChecked, setLocationChecked] = useState(false);
  const [isInTerritory, setIsInTerritory] = useState(false); // if TRUE == modal layer enabled (for users outside the Territory), if FALSE == modal layer enabled for me too
  const [activities, setActivities] = useState([]);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [selectedActivityId, setSelectedActivityId] = useState(null);
  const [availableCategories, setAvailableCategories] = useState([]);
  const [activeCategory, setActiveCategory] = useState("martialArts");
  const [showTestimonialsModal, setShowTestimonialsModal] = useState(false);
  const [selectedTestimonialActivity, setSelectedTestimonialActivity] = useState(null);
  const [selectedCity, setSelectedCity] = useState(() => {
    return localStorage.getItem("selectedCity") || "Новосибирск";
  });
  const [currentZoom, setCurrentZoom] = useState(10);
  const [isInitialMovementComplete, setIsInitialMovementComplete] = useState(false);
  
  const { activityId: directUrlActivityId } = useParams();
  const location = useLocation();

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsPageLoaded(true);
    }, 100);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (!locationChecked) {
      getUserLocation();
    }

    if (!isInTerritory || map.current) return;

    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/streets-v11",
      center: [82.9346, 55.0415], // Novosibirsk coords
      zoom: 10,
      langauge: "ru",
    });

    map.current.on("load", () => {
      // Set the default language to Russian
      map.current.setLayoutProperty("country-label", "text-field", [
        "get",
        "name_ru",
      ]);
      map.current.setLayoutProperty("state-label", "text-field", [
        "get",
        "name_ru",
      ]);
      map.current.setLayoutProperty("settlement-label", "text-field", [
        "get",
        "name_ru",
      ]);
      map.current.setLayoutProperty(
        "settlement-subdivision-label",
        "text-field",
        ["get", "name_ru"]
      );
      map.current.setLayoutProperty("road-label", "text-field", [
        "get",
        "name_ru",
      ]);

      map.current.on("zoom", () => {
        setCurrentZoom(map.current.getZoom());
      });

      // Load categories for both flows
      fetchAvailableCategories();

      // Handle direct URL vs base URL differently
      if (directUrlActivityId && location.pathname.includes('/activities/')) {
        const category = location.pathname.split('/')[2];
        
        fetchActivities(category).then((activities) => {
          const activity = activities.find(a => a.id === directUrlActivityId);
          if (activity && activity.locations?.[0]?.addresses?.[0]) {
            const { latitude, longitude } = activity.locations[0].addresses[0];
            
            map.current.flyTo({
              center: [longitude, latitude],
              zoom: 15
            });

            map.current.once("moveend", () => {
              setActiveCategory(category);
              setIsInitialMovementComplete(true);
              handleActivityMarkerClick({
                id: directUrlActivityId,
                category: category
              });
            });
          }
        });
      } else {
        // Base URL flow - get user location
        getUserLocation();
      }
    });

    return () => {
      if (map.current) {
        map.current.remove();
        map.current = null;
      }
    };
  }, [isInTerritory, locationChecked]);

  useEffect(() => {
    if (map.current) {
      fetchAvailableCategories();
    }
  }, [selectedCity]);

  useEffect(() => {
    if (activities.length > 0) {
      updateMapMarkers(activities);
    }
  }, [isInitialMovementComplete]);

 

  const getUserLocation = () => {


    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { longitude, latitude } = position.coords;

          // First super fast local check, no API
          const inTerritory = checkLocation(latitude, longitude);
          setIsInTerritory(inTerritory);
          setLocationChecked(true);

          if (inTerritory) {
            const detectedCity = detectUserCity(latitude, longitude);
            setSelectedCity(detectedCity);
            localStorage.setItem("selectedCity", detectedCity); // change to setItem from getItem
          }

          // Only center map if in Territory
          if (inTerritory && map.current) {
            map.current.flyTo({
              center: [longitude, latitude],
              zoom: 15,
            });
            //Listen for the moveend event to know when flyTo is complete
            map.current.once("moveend", () => {
              //console.log("DEBUG! ---> Initial movement complete");
              setIsInitialMovementComplete(true);
            });
          }
        },
        (error) => {
          // Fallback to city center if permission denied by the User (set as Failed if denied)
          if (error.code === error.PERMISSION_DENIED) {
            setIsInTerritory(false);
          
            setIsInitialMovementComplete(true); // Set even if denied
            setLocationChecked(true);
          }
        }
      );
    } else {
      setIsInTerritory(false);
      setIsInitialMovementComplete(true); // Set if geolocation not available
    }
  };

  const fetchAvailableCategories = async () => {
    try {
      const activitiesRef = collection(db, "activities");
      const activitiesQuery = query(
        activitiesRef,
        where("isActive", "==", true),
        where("isComplete", "==", true)
      );

      const querySnapshot = await getDocs(activitiesQuery);
      const categories = [];

      // Check both mainCity and locations array
      querySnapshot.docs.forEach((doc) => {
        const data = doc.data();
        const hasLocationInCity = data.locations?.some(
          (loc) => loc.city === selectedCity
        );
        if (data.mainCity === selectedCity || hasLocationInCity) {
          categories.push(data.category);
        }
      });

      // Sort and set unique categories
      const uniqueCategories = [...new Set(categories)];
      const sortedCategories = categoryData
        .filter((category) => uniqueCategories.includes(category.id))
        .map((category) => category.id);

      setAvailableCategories(sortedCategories);

      // ONLY set default active actegory for base URL flow 
      if (!location.pathname.includes('/activities/')) {
        setActiveCategory(sortedCategories[0] || "football");
        fetchActivities(sortedCategories[0] || "football");
      }
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  const fetchActivities = async (category = activeCategory) => {
    console.log('Fetching ativities for category --->', category);

    const activitiesRef = collection(db, "activities");
    const activitiesQuery = query(
      activitiesRef,
      where("category", "==", category),
      where("isActive", "==", true),
      where("isComplete", "==", true)
    );

    try {
      const querySnapshot = await getDocs(activitiesQuery);
      //console.log("Number of documents:", querySnapshot.docs.length);

      const fetchedActivities = await Promise.all(
        querySnapshot.docs.map(async (doc) => {
          const data = doc.data();

          // Only process if activity belongs in selected city
          const hasLocationInCity = data.locations?.some(
            (loc) => loc.city === selectedCity
          );
          if (!hasLocationInCity && data.mainCity !== selectedCity) {
            return null;
          }

          // Filter locations to only show addresses in selected city
          const filteredLocations = data.locations.filter(
            (loc) => loc.city === selectedCity
          );

          if (!filteredLocations.length) {
            console.warn(
              `Activity ${doc.id} has no locations in ${selectedCity}`
            );
            return null;
          }

          const geolocatedLocations = await Promise.all(
            filteredLocations.map(async (location) => {
              const addressesArray = Array.isArray(location.addresses)
                ? location.addresses
                : [location.addresses];

              const geolocatedAddresses = await Promise.all(
                addressesArray.map(async (addressData) => {
                  const { address, postIndex } = addressData;
                  const coords = await geocodeAddress(
                    address,
                    postIndex,
                    location.city
                  );
                  return { ...addressData, ...coords };
                })
              );

              return { ...location, addresses: geolocatedAddresses };
            })
          );

          return {
            id: doc.id,
            ...data,
            locations: geolocatedLocations,
          };
        })
      );

      const validActivities = fetchedActivities.filter(
        (activity) => activity !== null
      );
      setActivities(validActivities);
      updateMapMarkers(validActivities);
      return validActivities;
    } catch (error) {
      console.error("Error fetching activities:", error);
      return [];
    }
  };

  // function getCategoryIcon(category) {
  //   const categoryItem = categoryData.find((item) => item.id === category);
  //   return categoryItem ? categoryItem.icon : "default-icon.png";
  // }

  const updateMapMarkers = (activities) => {
    console.log('Updating markers with activities --->', activities);
    // Remove existing markers
    const markers = document.getElementsByClassName("mapboxgl-marker");
    while (markers[0]) {
      markers[0].parentNode.removeChild(markers[0]);
    }

    if (!activities || !Array.isArray(activities)) {
      return;
    }

    activities.forEach(async (activity) => {
      if (!activity || !activity.locations || activity.locations.length === 0) {
        return;
      }

      try {
        await analyticsService.initializeAnalytics(
          activity.id,
          activity.businessName
        );

        const locationsArray = Array.isArray(activity.locations)
          ? activity.locations
          : [activity.locations];

        locationsArray.forEach((location) => {
          if (
            !location ||
            !location.addresses ||
            location.addresses.length === 0
          ) {
            return;
          }

          const addressesArray = Array.isArray(location.addresses)
            ? location.addresses
            : [location.addresses];

          addressesArray.forEach((address) => {
            if (!address || !address.latitude || !address.longitude) {
              return;
            }

            // Single container for React
            const el = document.createElement("div");
            el.className = "map-category-item";
            
            // const iconContainer = document.createElement("div");
            // iconContainer.className = "map-cb-category-item-container";
            // const icon = document.createElement("img");
            // icon.src = getCategoryIcon(activity.category);
            // icon.alt = activity.category;
            // icon.className = "map-category-icon";
            // iconContainer.appendChild(icon);
            // el.appendChild(iconContainer);

            // Create Activity Marker first, but don't add to map
            const marker = new mapboxgl.Marker(el)
              .setLngLat([address.longitude, address.latitude])
              .addTo(map.current);

            el._mapboxMarker = marker;

            const markerData = {
              id: activity.id,
              category: activity.category,
              businessName: activity.businessName,
              logo: activity.logo || activity.logoUrl,
              price: activity.price,
              parentTestimonials: activity.parentTestimonials
            };

            const root = createRoot(el);
            root.render(
              <ActivityMarker
                activity={markerData} // it was activity
                //category={activity.category}
                map={map.current}
                onActivityClick={async () => {
                  try {
                    await analyticsService.trackMapMarkerClicks(markerData.id); // it was activity.id
                    handleActivityMarkerClick(markerData);
                  } catch (error) {
                    console.error("Error tracking marker click:", error);
                    handleActivityMarkerClick(markerData);
                  }
                }}
                onBecameVisible={async () => {
                  try {
                    await analyticsService.trackMapMarkerOrModalView(
                      activity.id,
                      "map"
                    );
                  } catch (error) {
                    console.error("Error tracking marker view:", error);
                  }
                }}
                onReviewClick={handleReviewClick}
                currentZoom={currentZoom}
                isInitialMovementComplete={isInitialMovementComplete}
              />
            );
          });
        });
      } catch (error) {
        console.error("Error setting up marker:", error);
      }
    });
  };

  const handleCategoryClick = (category) => {
    // First, we clear any selected activity first
    setSelectedActivityId(null);

    // Next, we reset the URL to base URL
    window.history.pushState(null, null, '/');

    // Then we switch category
    setActiveCategory(category);
    fetchActivities(category);
  };

  const handleActivityMarkerClick = (markerData) => {
    // This will update URL making us visible for Search Engines
    window.history.pushState(
      {}, // state equals null
      '', // title equals null
      `/activities/${markerData.category}/${markerData.id}`
    );
    // This will open a ActivityProviderPageModal
    setSelectedActivityId(markerData.id);
  };

  const handleActivityProviderPageModalClose = () => {
    // First, we clear selected activity
    setSelectedActivityId(null);

    // Next, we reset the URL to base map
    window.history.pushState(null, null, '/');
  };

  const handleReviewClick = (activity) => {
    // This will open a ReviewModal
    setSelectedTestimonialActivity(activity);
    setShowTestimonialsModal(true);
  };

  const handleCloseTestimonailsModal = () => {
    setShowTestimonialsModal(false);
    setSelectedTestimonialActivity(null);
  };

  const handleCityChange = (newCity) => {
    setSelectedCity(newCity);
    localStorage.setItem("selectedCity", newCity);
    setActiveCategory(null);
  };

  const schemaMarkup = {
    "@context": "https://schema.org",
    "@type": "WebSite",
    name: "ДляДетей.ру",
    url: "https://ddetey.ru",
    description:
      "Экономьте время на поиски занятий и услуг для своих детей с платформой ДляДетей.ру",
  };

  return (
    <div className='map-home'>
      {!locationChecked ? (
        // Loading state
        <div className='map-home-loading-container'>
          <div
            className='mhoct-background-image'
            style={{
              backgroundImage:
                "url(/general_images/ДляДетей.ру_сайт_платформа_карта.JPG)",
            }}
          >
            <div className='mhoct-modal-container-location-check'>
              <div className='mhoct-modal-body'>
                <h2 style={{ marginBottom: "0" }}>
                  Проверяем Ваше местоположение...
                </h2>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          {!isInTerritory ? (
            // Out of territory modal
            <div className='map-home-outside-current-territory'>
              <div
                className='mhoct-background-image'
                style={{
                  backgroundImage:
                    "url(/general_images/ДляДетей.ру_сайт_платформа_карта.JPG)",
                }}
              >
                <div className="mhoct-modal-wrapper">
                  <div className='mhoct-modal-container'>
                    <div className='mhoct-modal-body'>
                      <h2>
                        ДляДетей.ру пока что доступен в Новосибирске, Бердске, Академгородке и Искитиме.
                      </h2>
                      <p>Чтобы не пропустить, какой город будет достуен следующим, следите за новостями в ТГ или ВК.</p>
                      <div className='mhoct-social-media-buttons'>
                        <a href='https://t.me/ddeteyru'className='mhoct-social-button'>Телеграм</a>
                        <a href='https://vk.com/ddeteyru' className='mhoct-social-button'>ВКонтакте</a>
                      </div>
                    </div>
                  </div>

                  {/* Info icon modal */}
                  <div className='mhoct-modal-container-info'>
                    <div className='mhoct-modal-body'>
                      <p style={{ margin: 0, fontSize: '16px', fontWeight: 500 }}>
                        <FaInfoCircle style={{ marginRight: '8px', color: "#f21170", fontSize: '18px', display: 'inline-block', verticalAlign: 'text-bottom' }} />
                        Если карта не загружается, пожалуйста, включите и разрешите использовать геолокацию в настройках вашего устройства (Safari, Chrome и тд). Затем обновите страницу.
                      </p>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          ) : (
            <>
              <SEO
                title='ДляДетей.ру'
                description='ДляДетей.ру — это интерактивная карта для поиска детских занятий и услуг в вашем городе. Экономия времени, удобный поиск, полное описание, специальные предложения и прочее.'
                keywords={allKeywords}
                image='/general_images/для-детей-ру-главное-изображение.png' //image url
                url='https://ddetey.ru/'
                ogType='website'
                schemaMarkup={schemaMarkup}
                canonical={`https://ddetey.ru${location.pathname}`}
              />
              <div className='map-home-content'>
                <div
                  className={`map-home-header-wrapper 
                  ${selectedActivityId || selectedTestimonialActivity || isMenuOpen ? "slide-up" : ""}`}
                  style={{ transform: !isPageLoaded ? 'translateY(-100%)' : 'translateY(0)'}}>

                  <Header
                    onCityChange={handleCityChange}
                    activity={selectedTestimonialActivity}
                    selectedCity={selectedCity}
                    className='map-home-header'
                    onMenuStateChange={setIsMenuOpen}
                  >
                  </Header>
                </div>

                {/* MENU MODAL */}
                {isMenuOpen && (
                  <div className='menu-modal-overlay' onClick={() => setIsMenuOpen(false)}>
                    <div className='menu-modal' onClick={(e) => e.stopPropagation()}>
                      <button className='close-button' onClick={() => setIsMenuOpen(false)}>
                        <FaTimes size={24} color='#72147e' />
                      </button>
                      <nav className='menu-nav'>
                      <div className='modal-menu-city-selector'>
                        <CitySelector
                          selectedCity={selectedCity}
                          onChange={handleCityChange}
                          className='modal-city-selector'
                        />
                      </div>
                        <ul>
                          {/* <li><a href='/about-us'>О нас</a></li>  not available atm */}
                          <li><a href='/join-us'>Для организаций</a></li>
                          {/* <li><a href='/advertise-with-us'>Для рекламодателей</a></li>  not available atm */}
                          {/* <li><a href='/contacts'>Контакты</a></li>  not available atm */}
                          <li><a href='/blog'>Блог</a></li>
                          <li><a href='/skazki'>Сказки для детей</a></li>
                          {/* <li><a href='/special-offers'>Специальные предложения</a></li>  not available atm */}
                          <li><a href='/terms-of-service'>Условия использования</a></li>
                          <li><a href='/privacy-policy'>Политика конфиденциальности</a></li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                )}

                <div
                  className={`map-home-category-bar-wrapper
                  ${selectedActivityId || selectedTestimonialActivity || isMenuOpen ? "slide-down" : ""}`}
                  style={{ transform: !isPageLoaded ? 'translateY(100%)' : 'translateY(0)'}}>
                  <CategoryBar
                    onCategoryClick={handleCategoryClick}
                    activity={selectedTestimonialActivity}
                    activeCategory={activeCategory}
                    availableCategories={availableCategories}
                    className='map-home-category-bar'
                  />
                </div>

                {/* MAP CONTAINER */}
                <div className='map-container' ref={mapContainer} />

                {/* ActivityProviderPageModal */}
                {selectedActivityId && (
                  <div
                    className='map-container-modal'
                    onClick={handleActivityProviderPageModalClose}
                  >
                    <div
                      className='map-container-modal-activity-provider-page'
                      onClick={(e) => e.stopPropagation()}
                    >
                      <button
                        className='mcmapp-close-button'
                        onClick={handleActivityProviderPageModalClose}
                      >
                        <FaTimes size={24} color='#35183c' />
                      </button>
                      <ActivityProviderPage
                        providedActivityId={selectedActivityId}
                      />
                    </div>
                  </div>
                )}

                {/* ActivityCardTestimonialsModal */}
                {showTestimonialsModal && selectedTestimonialActivity && (
                  <ActivityCardTestimonialsModal
                    activity={selectedTestimonialActivity}
                    onClose={handleCloseTestimonailsModal}
                  />
                )}

              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default MapHome;