import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { OnboardingContext } from '../../../../context/OnboardingContext';
import './OnboardingSuccess.css';

const OnboardingSuccess = () => {
    const { onboardingData, setOnboardingData } = useContext(OnboardingContext);
    const { firstName } = onboardingData;
    const navigate = useNavigate();
    const handleNavigateToDashboard = () => {
        navigate('/activity-provider/dashboard');
    }

    useEffect(() => {
        setOnboardingData(prevData => ({
            ...prevData,
            firstName,
        }));
    }, [firstName]);

    return (
        <div className="onboarding-success">
            <h2>{`${firstName}, Ваш аккаунт успешно создан!`}</h2>
            <span style={{fontSize: '20px', fontWeight: '600', color: '#f21170'}}>Теперь переходите в личный кабинет и добавляйте Ваше объявление.</span> 
            <p>Если хотите быть в курсе обо всех нововведениях, то вступайте в <a href="https://t.me/ddeteyru_biz" target="_blank" rel="noopener noreferrer" className="os-telegram-href">Телеграм канал</a> для Бизнеса.</p>
            <button onClick={handleNavigateToDashboard} className="os-next-button">Перейти в личный кабинет</button>
                <div className="os-confetti-container">
                    {[...Array(75)].map((_, i) => (
                        <div key={i} className={`confetti confetti-${i % 3}`}></div>
                    ))}
                </div>
        </div>
    );
};

export default OnboardingSuccess;