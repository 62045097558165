import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { db } from '../../../../firebase/FirebaseConfig';
import { doc, getDoc, updateDoc, deleteDoc, collection, where, query, getDocs, writeBatch } from 'firebase/firestore';
import { updateEmail, updatePassword, deleteUser, reauthenticateWithCredential, EmailAuthProvider } from 'firebase/auth';
import { useAuth } from '../../../../hooks/useAuth';
import './ActivityProviderSettings.css';

const ActivityProviderSettings = () => {
    const [userData, setUserData] = useState({
        firstName: '',
        middleName: '',
        lastName: '',
        contactNumber: '',
        email: '',
    });
    const { user, loading, logout } = useAuth ();
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchUserData = async () => {
            if (!user) return;
            try {
                    const userDoc = await getDoc(doc(db, 'activityProviders', user.uid));
                    if (userDoc.exists()) {
                        const data = userDoc.data();
                        setUserData({
                            firstName: data.firstName || '',
                            middleName: data.middleName || '',
                            lastName: data.lastName || '',
                            contactNumber: data.contactNumber || '',
                            email: data.email || user.email,
                            subscriptionPlan: data.subscriptionPlan || '',
                            nextPaymentDate: data.nextPaymentDate || null
                        });
                }
            } catch (error) {
                console.error("DEBUG: ---> Error fetching user data:", error);
                setError('Ошибка загрузки данных пользователя');
            } finally {
                setIsLoading(false);
            }
        };

        if (user) {
            fetchUserData();
        } else if (!loading) {
            navigate('/activity-provider/onboarding/step1')
        }
    }, [user, loading, navigate]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setUserData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setSuccess('');
        setIsLoading(true);

        try {
            if (user) {
                // THIS updates Firestore document
                await updateDoc(doc(db, 'activityProviders', user.uid), {
                    firstName: userData.firstName,
                    middleName: userData.middleName,
                    lastName: userData.lastName,
                    contactNumber: userData.contactNumber,
                });

                //THIS updates email if it was changed
                if (user.email !== userData.email) {
                    await updateEmail(user, userData.email);
                }

                //THIS updates password if it was changed
                if (newPassword) {
                    await updatePassword(user, newPassword);
                }

                setSuccess("Ваши данные были успешно обновлены");
            }
        } catch (error) {
            setError('Ошибка! Не удалось обновить данные');
        } finally {
            setIsLoading(false);
        }
    };

    const handleDeleteAcctount = async () => {
        if (window.confirm("Вы уверены, что хотите удалить свой аккаунт?")) {
            try {
                setIsLoading(true);
                if (user) {
                    //THIS re-authenticates user before deletion
                    const credential = EmailAuthProvider.credential(user.email, currentPassword);
                    await reauthenticateWithCredential(user, credential);

                    //THIS deletes all associated activity data
                    const activitiesRef = collection(db, 'activities');
                    const q = query(activitiesRef, where("providerId", "==", user.uid));
                    const querySnapshot = await getDocs(q);
                    const batch = writeBatch(db);
                    querySnapshot.forEach((doc) => {
                        batch.delete(doc.ref);
                    });
                    await batch.commit();

                    //THIS deletes Firestore document
                    await deleteDoc(doc(db, 'activityProviders', user.uid));

                    //THIS deletes user account
                    await deleteUser(user);

                    //THIS clears any stored auth tokens or user data
                    localStorage.removeItem('user');
                    sessionStorage.removeItem('user');

                    //THIS redirects user to the homepage
                    // we might need to use React Router's navigation instead, idk \('<')/
                    //window.location.href = "/";
                    await logout();
                    navigate('/activity-provider/onboarding/step1')
                }
            } catch (error) {
                console.error("DEBUG: ---> Error deleting account:", error);
                setError('Ошибка! Не удалось удалить пользователя');
            } finally {
                setIsLoading(false);
            }
        }
    };

    if (loading || isLoading) {
        return <div className="aps-loading">Загрузка...</div>;
    }

    const FormDivider = () => {
        return <hr className="aps-form-divider" />;
    };

    return (
        <div className="activity-provider-settings">
            <div className="aps-title">
                <h2>Настройки Аккаунта</h2>
            </div>
            {error && <div className="aps-error">{error}</div>}
            {success && <div className="aps-success">{success}</div>}
            <div className="aps-form-container">
                <form onSubmit={handleSubmit}>
                    <div className="aps-form-group">
                        <label htmlFor="lastName">Фамилия</label>
                        <input
                            type="text"
                            id="lastName"
                            name="lastName"
                            value={userData.lastName}
                            onChange={handleInputChange}
                            placeholder="Пушкин"
                        />
                    </div>
                    <div className="aps-form-group">
                        <label htmlFor="firstName">Имя</label>
                        <input
                            type="text"
                            id="firstName"
                            name="firstName"
                            value={userData.firstName}
                            onChange={handleInputChange}
                            placeholder="Александр"
                        />
                    </div>
                    <div className="aps-form-group">
                        <label htmlFor="middleName">Отчество</label>
                        <input
                            type="text"
                            id="middleName"
                            name="middleName"
                            value={userData.middleName}
                            onChange={handleInputChange}
                            placeholder="Сергеевич"
                        />
                    </div>
                    <div className="aps-form-group">
                        <label htmlFor="contactNumber">Контактный телефон</label>
                        <input
                            type="tel"
                            id="contactNumber"
                            name="contactNumber"
                            value={userData.contactNumber}
                            onChange={handleInputChange}
                            pattern="[+\d]+"
                            placeholder="8923444488"
                        />
                    </div>
                    <div className="aps-form-group">
                        <label htmlFor="email">Email</label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            value={userData.email}
                            onChange={handleInputChange}
                            placeholder="al.pushkin@yandex.ru"
                        />
                    </div>
                    <div className="aps-form-group">
                        <label htmlFor="newPassword">Поменять пароль</label>
                        <input
                            type="password"
                            id="newPassword"
                            name="newPassword"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            placeholder="Введите новый пароль"
                        />
                    </div>
                    <button type="submit" className="aps-submit-button" disabled={isLoading}>
                        {isLoading ? 'Обновляю...' : 'Обновить данные'}
                    </button>
                </form>
            <FormDivider />
                <div className="aps-danger-zone">
                    <h3>Удаление аккаунта</h3>
                    <p>Удаление аккаунта навсегда удалит все данные с ДляДетей.ру и их невозможно будет восстановить.</p>
                    <input 
                        type="password"
                        placeholder="Введите пароль"
                        value={currentPassword}
                        onChange={(e) => setCurrentPassword(e.target.value)}
                    />
                    <button onClick={handleDeleteAcctount} className="aps-delete-button">Удалить аккаунт</button>
                </div>
            </div>
        </div>
    );
};

export default ActivityProviderSettings;