import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { db, auth } from '../../../../../firebase/FirebaseConfig';
import { collection, addDoc, getDoc, doc } from 'firebase/firestore';
import './ActivityProviderAddSpecialOffer.css';

const ActivityProviderAddSpecialOffer = () => {
  const [offerData, setOfferData] = useState({
    title: '',
    description: '',
    startDate: '',
    endDate: '',
    availableSpots: '',
    keyAction: '',
  });
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setOfferData(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);
    
    try {
      const user = auth.currentUser;
      if (!user) throw new Error('User not authenticated');

      // Fetch the activity document to get the activityId
      const activityProviderRef = doc(db, 'activityProviders', user.uid);
      const activityProviderSnap = await getDoc(activityProviderRef);
      
      if (!activityProviderSnap.exists()) {
        throw new Error('Activity provider document not found');
      }
      
      const activityId = activityProviderSnap.data().activityId;
      
      if (!activityId) {
        throw new Error('Activity ID not found for this provider');
      }

      const offerRef = await addDoc(collection(db, 'specialOffers'), {
        ...offerData,
        providerId: user.uid,
        activityId: activityId,
        createdAt: new Date()
      });

      console.log('Special offer added: ', offerRef); //.id
      navigate('/activity-provider/special-offers-list');
    } catch (error) {
      console.error("Error adding special offer: ", error);
      setError('Ошибка! Не удалось создать специальное предложение: ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="add-special-offer-container">
      <h2>Добавить специальное предложение</h2>
      {error && <div className="error-message">{error}</div>}
      <form onSubmit={handleSubmit} className="add-special-offer-form">
        <div className="form-group">
          <label htmlFor="title">Заголовок</label>
          <input
            type="text"
            id="title"
            name="title"
            value={offerData.title}
            onChange={handleChange}
            placeholder="Набор в новую группу по футболу для детей от 5 лет"
            required
          />
        </div>
        
        <div className="form-group">
          <label htmlFor="description">Описание предложения</label>
          <textarea
            id="description"
            name="description"
            value={offerData.description}
            onChange={handleChange}
            rows="3"
            placeholder="Все родители, которые приобретут абонемент до 14го Октября получат скидку 25%. В дополнение к скидке, каждый ребенок получит бесплатно фирменную футболку и кепку."
            required
          ></textarea>
        </div>
        
        <div className="form-group date-range">
          <label>Длительность акции</label>
          <div className="date-inputs">
            <input
              type="date"
              name="startDate"
              value={offerData.startDate}
              onChange={handleChange}
              required
            />
            <span>до</span>
            <input
              type="date"
              name="endDate"
              value={offerData.endDate}
              onChange={handleChange}
              required
            />
          </div>
        </div>
        
        <div className="form-group">
          <label htmlFor="availableSpots">Сколько осталось свободных мест</label>
          <input
            type="number"
            id="availableSpots"
            name="availableSpots"
            value={offerData.availableSpots}
            onChange={handleChange}
            placeholder="5"
          />
        </div>
        
        <div className="form-group">
          <label htmlFor="keyAction">Ключевое действие (КД)</label>
          <select
            id="keyAction"
            name="keyAction"
            value={offerData.keyAction}
            onChange={handleChange}
            required
          >
            <option value="">Выбрать действие</option>
            <option value="call">Позвонить</option>
            <option value="whatsapp">Написать в WhatsApp</option>
            <option value="email">Отправить заявку на Email</option>
          </select>
        </div>
        
        <button type="submit" className="submit-button" disabled={loading}>
          {loading ? 'Сохранение...' : 'Сохранить'}
        </button>
      </form>
    </div>
  );
};

export default ActivityProviderAddSpecialOffer;