import mapboxgl from 'mapbox-gl';

export const geocodeAddress = async (address, postIndex, city) => {
    const searchString = `${address}, ${postIndex}, ${city}, Russia`;
    const endpoint = `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(searchString)}.json`;
    const params = new URLSearchParams({
        access_token: mapboxgl.accessToken,
        limit: 1,
        country: 'RU',
        types: 'address'
    });

    try {
        const response = await fetch(`${endpoint}?${params}`);
        const data = await response.json();

        if (data.features && data.features.length > 0) {
            const [longitude, latitude] = data.features[0].center;
            return { latitude, longitude };
        } else {
            console.warn(`DEBUG! ---> No results found on address: ${searchString}`);
            return null;
        }
    } catch (error) {
        console.error('DEBUG! ---> Error geocoding address:', error);
        return null;
    }
};