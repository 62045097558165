import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { db } from '../../../../firebase/FirebaseConfig';
import { collection, addDoc, doc, getDoc, updateDoc } from 'firebase/firestore';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'
import '../../../global/QuillCustomStyles.css';
import './AddTermsForm.css';

const AddTermsForm = () => {
  const { termId } = useParams();
  const [termsData, setTermsData] = useState({
    title: '',
    content: '',
    lastUpdated: ''
  });
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (termId) {
        setLoading(true);
        const fetchTermsDatafromDB = async () => {
            try {
                console.log("DBEUG! ---> fetching existing terms....");
                const termDocRef = doc(db, 'terms', termId);
                const termDocSnapshot = await getDoc(termDocRef);
                console.log("SUCCESS! ---> terms fetched successfully: ", termId);
                if (termDocSnapshot.exists()) {
                    setTermsData(termDocSnapshot.data());
                } else {
                    setError('Условия пользования не найдены');
                }
            } catch (error) {
                setError('Ошибка! Невозможно загрузить Услвоия использования');
            } finally {
                setLoading(false);
            }
        };
        fetchTermsDatafromDB();
    }
  }, [termId]);

  const quillModules = {
    toolbar: {
      container: [
        [{ 'header': [1, 2, 3, false] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{'list': 'ordered'}, {'list': 'bullet'}],
        [{ 'script': 'sub'}, { 'script': 'super' }],
        [{ 'indent': '-1'}, { 'indent': '+1' }],
        [{ 'direction': 'rtl' }],
        [{ 'color': ['#f21170', '#72147e', '#35183c', '#323232', 'white'] }, { 'background': ['#f21170', '#72147e', '#35183c', '#323232', 'white'] }],
        [{ 'align': [] }],
        ['link', 'blockquote'],
        ['clean']
      ],
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setTermsData(prev => ({ ...prev, [name]: value }));
  };

  const handleContentChange = (content) => {
    setTermsData(prev => ({ ...prev, content }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);
    console.log('Terms data submitted to Firebase DB:', termsData);
    try {
      if (termId) {
        // Update existing terms
        const termDocRef = doc(db, 'terms', termId);
        await updateDoc(termDocRef, {
          title: termsData.title,
          content: termsData.content,
          lastUpdated: termsData.lastUpdated
        });
        console.log('DEBUG: ---> Terms updated with ID: ', termId);
      } else {
        // Add new terms
        const termsRef = await addDoc(collection(db, 'terms'), {
          title: termsData.title,
          content: termsData.content,
          lastUpdated: termsData.lastUpdated,
          createdAt: new Date()
        });
        console.log('DEBUG: ---> Terms added with ID: ', termsRef.id);
      }
      navigate('/admin/activity-providers');
    } catch (error) {
      console.error("DEBUG: ---> Error saving terms: ", error);
      console.error("DEBUG! ---> Error details:", error.code, error.message);
      setError('Ошибка! Не удалось сохранить условия использования');
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <div className="loading">Загружаю...</div>;
  if (error) return <div className="error">{error}</div>;

  return (
    <div className="add-terms-form-container">
      <h2>{termId ? 'Редактировать Условия использования' : 'Добавить условия использования'}</h2>
      {error && <div className="tos-error-message">{error}</div>}
      <form onSubmit={handleSubmit} className="add-terms-form">
        <div className="form-group">
          <label htmlFor="title">Заголовок</label>
          <input
            type="text"
            id="title"
            name="title"
            value={termsData.title}
            onChange={handleChange}
            placeholder="Введите заголовок условий использования"
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="content">Текст Условий использования</label>
          <ReactQuill
            value={termsData.content}
            onChange={handleContentChange}
            modules={quillModules}
            theme="snow"
          />
        </div>
        <div className="form-group">
          <label htmlFor="lastUpdated">Дата последнего обновления</label>
          <input
            type="date"
            id="lastUpdated"
            name="lastUpdated"
            value={termsData.lastUpdated}
            onChange={handleChange}
            required
          />
        </div>
        <button type="submit" className="submit-button" disabled={loading}>
          {loading ? 'Сохранение...' : 'Сохранить'}
        </button>
      </form>
    </div>
  );
};

export default AddTermsForm;