import React from 'react';
import './Stepper.css';

const Step = ({ title, isActive, isCompleted, stepNumber }) => (
    <div className={`step ${isActive ? 'active' : ''} ${isCompleted ? 'completed' : ''}`}>
        <div className="step-indicator"> {isCompleted ? '✓' : stepNumber}</div>
        <div className="step-title">{title}</div>    
    </div>
);

const Stepper = ({ steps, currentStep }) => (
    <div className="stepper">
        {steps.map((step, index) => (
        <Step
            key={index}
            title={step.title}
            isActive={index === currentStep}
            isCompleted={index < currentStep}
            stepNumber={index + 1}
        />
       ))}
    </div>
);

export default Stepper;