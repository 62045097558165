import React from 'react';
import { Link } from 'react-router-dom';
import './Breadcrumbs.css';

const Breadcrumbs = ({ items }) => {
  return (
    <div className="breadcrumb-container">
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
                {items.map((item, index) => (
                <li 
                    key={index} 
                    className={`breadcrumb-item ${index === items.length - 1 ? 'active' : ''}`}
                >
                    {index === items.length - 1 ? (
                    <span>{item.name}</span>
                    ) : (
                    <Link to={item.path}>{item.name}</Link>
                    )}
                </li>
                ))}
            </ol>
        </nav>
    </div>
  );
};

export default Breadcrumbs;