import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { db, auth } from '../../../../firebase/FirebaseConfig';
import { doc, getDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';
import './ActivityProviderSpecialOffers.css';

const ActivityProviderSpecialOffers = () => {
  const [providerData, setProviderData] = useState(null);
  const [specialOffers, setSpecialOffers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  // Fetch provider data and special offers
  const fetchData = async (userId) => {
    try {
      const providerDocRef = doc(db, 'activityProviders', userId);
      const providerDoc = await getDoc(providerDocRef);
      if (providerDoc.exists()) {
        setProviderData(providerDoc.data());
        
        // Fetch special offers
        const offersQuery = query(collection(db, 'specialOffers'), where('providerId', '==', userId));
        const offersSnapshot = await getDocs(offersQuery);
        
        const offersData = await Promise.all(offersSnapshot.docs.map(async offerDoc => {
          const offerData = offerDoc.data();
        
          // Fetch the corresponding activity using the providerId (userId)
          const activityQuery = query(collection(db, 'activities'),
            where('providerId', '==', userId));
          const activitySnapshot = await getDocs(activityQuery);
        
          if (activitySnapshot.empty) {
            return {
              id: offerDoc.id,
              ...offerData,
              views: 0,
              clicks: 0,
              keyActions: 0,
              ctr: 0
            };
          }
        
          // Assuming there's only one activity per provider
          const activityDoc = activitySnapshot.docs[0];
          const activityData = activityDoc.data();
        
          // Get the data from the activity document
          const views = activityData.specialOfferViews || 0;
          const clicks = activityData.specialOfferClicks || 0;
          const keyActions = activityData.keyActionsSpecialOffer?.total || 0;
          const ctr = views > 0 ? ((clicks / views) * 100).toFixed(2) : 0;
        
          return {
            id: offerDoc.id,
            ...offerData,
            views: views,
            clicks: clicks,
            keyActions: keyActions,
            ctr: ctr
          };
        }));
        
        setSpecialOffers(offersData);
      } else {
        setError("Данные пользователя не найдены");
      }
    } catch (error) {
      setError(`Нельзя получить данные: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        fetchData(user.uid);
      } else {
        setError("Пожалуйста, войдите в систему");
        setLoading(false);
      }
    });
    return () => unsubscribe();
  }, []);

  const handleEditSpecialOffer = (offerId = null) => {
    if (offerId) {
      navigate(`/activity-provider/special-offers-list/${offerId}/edit`);
    } else {
      navigate('/activity-provider/special-offers-list/add-special-offer');
    }
  };

  if (loading) return <div className="so-loading">Загрузка...</div>;
  if (error) return <div className="so-error-message">{error}</div>;

  return (
    <div className="special-offers-dashboard">
      <div className="so-list-title">
        <h2>Специальные предложения</h2>
        <button 
          className="so-add-button" 
          onClick={() => handleEditSpecialOffer()}
          disabled={specialOffers.length >= 1} // Disable if limit reached
        >
          Добавить предложение
        </button>
      </div>
      <span className="so-list-subtitle">
        Здесь отображаются все Ваши специальные предложения. Текущий лимит: 1 предложение в месяц.
      </span>
      <div className="so-table-container">
        <table>
          <thead>
            <tr>
              <th>Название</th>
              <th>Статус</th>
              <th>Просмотров</th>
              <th>Кликов</th>
              <th>КД</th>
              <th>CTR</th>
            </tr>
          </thead>
          <tbody>
            {specialOffers.map(offer => (
              <tr key={offer.id} onClick={() => handleEditSpecialOffer(offer.id)}>
                <td style={{ cursor: 'pointer' }}>{offer.title || 'Без названия'}</td>
                <td>{offer.status || 'Активен'}</td>
                <td>{offer.views || 0}</td>
                <td>{offer.clicks || 0}</td>
                <td>{offer.keyActions || 0}</td>
                <td>{offer.ctr ? `${offer.ctr}%` : 0}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ActivityProviderSpecialOffers;