import React from 'react';
import { FaTag } from 'react-icons/fa';
import './SpecialOffer.css';

const SpecialOffer = ({ title, onSpecialOfferButtonClick }) => {
    return (
        <div className="special-offer">
            <div className="special-offer-content">
                <div className="special-offer-content-left">
                    <FaTag className="special-offer-icon" />
                    <span className="special-offer-title">{title}</span>
                </div>
            <button className="special-offer-button" onClick={onSpecialOfferButtonClick}>
                Узнать подробности
            </button> 
            </div>   
        </div>
    );
};

export default SpecialOffer;