import React from 'react';
import '../section_heading/SectionHeading.css';

const SectionHeading = ({ title, children, withArrows=false, onNext, onPrev }) => {
    return (
        
        <div className="section-heading">
            <div className="section-header">
                <h2 className="section-title">{title}</h2>
                {withArrows && (
                    <div className="navigation-arrows">
                        <button className="prev" onClick={onPrev}>❮</button>
                        <button className="next" onClick={onNext}>❯</button>
                    </div>    
                )}
            </div>
        <div className="section-content">
            {children}
        </div>
        </div>
    );
};

export default SectionHeading;
