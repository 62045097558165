import React from 'react';
import './MainImage.css';
import { FaCheckCircle } from 'react-icons/fa';

const MainImage = ({ mainImage, logo, businessName, isOnPaidPlan, inn }) => {
  return (
    <div 
      className="app-main-image" 
      style={{ backgroundImage: `url(${mainImage})` }}
    >
      <div className="app-logo-text-overlay">
        <img src={logo} alt="Logo" className="app-logo" />
        <div className="app-title-inn-wrapper">
          <div className="app-title">{businessName} {isOnPaidPlan && (
            <span className="app-verification-badge" title="Verified Provider">
              <FaCheckCircle />
            </span>
          )}
          </div>
          <div className="app-inn">ИНН: {inn}</div>
        </div>
      </div>
    </div>
  );
};

export default MainImage;
