import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../../firebase/FirebaseConfig';
import Header from '../../global/Header/Header';
import Footer from '../../global/Footer/Footer';
import SEO from '../../global/SEO';
import Breadcrumbs from '../../global/Breadcrumbs/Breadcrumbs';
import './Blog.css';

const Blog = () => {
  const { blogId, slug } = useParams();
  const [blog, setBlog] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const location = useLocation();

const generateBreadcrumbs = () => {
  const items = [{ name: 'Главная', path: '/' }];
  items.push({ name: 'Блог', path: '/blog' });
  if (blog) {
    items.push({ name: blog.title, path: location.pathname })
  }
  return items;
  };

  const breadcrumbItems = generateBreadcrumbs();

useEffect(() => {
  const fetchBlogByIdFromDB = async () => {
    try {
      const blogDocRef = doc(db, 'blogs', blogId);
      const blogDocSnapshot = await getDoc(blogDocRef);

      if (blogDocSnapshot.exists()) {
        setBlog({ 
          id: blogDocSnapshot.id, 
          ...blogDocSnapshot.data()
        });
      } else {
        setError('Ошибка! Блог статья не найдена');
      }
    } catch(error) {
      console.error("DEBUG: ---> Error fetching blog by id:", error);
      setError('Ошибка! Невозможно загрузить блог статью');
    } finally {
      setLoading(false);
    }
  };

  fetchBlogByIdFromDB();
}, [blogId, slug]);

if (loading) return <div>Загружа...</div>;
if (error) return <div>{error}</div>;
if (!blog) return <div>Блог статья с таким ID не найдена...</div>;

const schemaMarkup = {
  "@context": "https://schema.org",
  "@type": "BlogPosting",
  "headline": blog.title,
  "image": blog.image,
  "author": {
    "@type": "Organization",
    "name": "ДляДетей.ру"
  },
  "publisher": {
    "@type": "Organization",
    "name": "ДляДетей.ру",
    "logo": {
      "@type": "ImageObject",
      "url": "https://ddetey.ru/logo.png"
    }
  },
  "datePublished": blog.date,
  "description": blog.annotation,
  "breadcrumb": {
    "@type": "Breadcrumb:ist",
    "itemListElement": breadcrumbItems.map((item, index) => ({
      "@type": "ListItem",
      "position": index + 1,
      "name": item.name,
      "item": `https://ddetey.ru${item.path}`
    }))
  }
};

  return (
    <>
      <SEO
        title={blog.title}
        description={blog.annotation}
        keywords={[ blog.title, 'блог о детях', 'развитие детей', 'воспитание детей', 'детские кружки', 'советы родителям']}
        image={blog.image} 
        url={`https://ddetey.ru/blog/${blogId}`}
        ogType="article"
        schemaMarkup={schemaMarkup}
        canonical={`https://ddetey.ru${location.pathname}`} 
      />

      <Header />

      <Breadcrumbs items={breadcrumbItems} />

        <div className='blog-id-container'>
          <div style={{ marginBottom: '32px'}}>
            <div className='bic-image-and-title'>
              <img src={blog.image} alt={blog.title} className='bic-image' />
              <h2 className='bic-title'>{blog.title}</h2>
            </div>
            <div 
                className='bic-content'
                dangerouslySetInnerHTML={{ __html: blog.content }}
              />
            </div>
        </div>
      <Footer />
    </>
    
  );
};

export default Blog;
