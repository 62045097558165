import { useState } from "react";

const useNavigation = (length) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    
    const nextItem = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % length);
    };
    
    const prevItem = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + length) % length);
    };

    return { currentIndex, nextItem, prevItem };
};

export default useNavigation;