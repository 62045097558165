import React from 'react';
import './AboutUs.css';

const AboutUs = ({ aboutUs }) => {
  return (
    <div className="about-us">
      <p>{aboutUs}</p>
    </div>
  );
};

export default AboutUs;
