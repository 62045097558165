import React from 'react';
import { Helmet } from 'react-helmet';

const SEO = ({ 
  title, 
  description, 
  keywords, 
  image, 
  url, 
  ogType = 'website',
  robots = 'index, follow',
  canonical,
  schemaMarkup,
}) => {
  const siteTitle = "Интерактивная карта для поиска детских занятий и услуг в вашем городе.";
  const fullTitle = `${title} | ${siteTitle}`;

  return (
    <Helmet>
      <title>{fullTitle}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords.join(', ')} />
      <meta name="robots" content={robots} />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      {canonical && <link rel="canonical" href={canonical} />}
      
      {/* Open Graph / Facebook */}
      <meta property="og:type" content={ogType} />
      <meta property="og:url" content={url} />
      <meta property="og:title" content={fullTitle} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />

      {/* Twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:url" content={url} />
      <meta name="twitter:title" content={fullTitle} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />

      {/* VK */}
      <meta property="vk:image" content={image} />

      {/* Additional tags */}
      <meta property="og:site_name" content={siteTitle} />
      <meta property="og:locale" content="ru_RU" />

      {/* Schema Markup */}
      {schemaMarkup && 
        <script type="application/ld+json">
          {JSON.stringify(schemaMarkup)}
        </script>
      }
    </Helmet>
  );
};

export default SEO;