import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { collection, query, orderBy, getDocs, limit } from "firebase/firestore";
import { db } from "../../../firebase/FirebaseConfig";
import Header from "../../global/Header/Header";
import Footer from "../../global/Footer/Footer";
import SEO from "../../global/SEO";
import './PrivacyPolicyPage.css'; // New CSS file for PrivacyPolicyPage

const PrivacyPolicyPage = () => {
  const [privacyData, setPrivacyData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const fetchPrivacyFromDB = async () => {
      try {
        const privacyRef = collection(db, 'privacy');
        const q = query(privacyRef, orderBy('createdAt', 'desc'), limit(1));
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
          const privacyDoc = querySnapshot.docs[0];
          setPrivacyData({
            id: privacyDoc.id,
            ...privacyDoc.data()
          });
        } else {
          setError('Политика конфиденциальности не найдена');
        }
      } catch (error) {
        console.error("DEBUG: ---> Error fetching privacy policy:", error);
        setError('Ошибка! Невозможно загрузить политику конфиденциальности');
      } finally {
        setLoading(false);
      }
    }
    fetchPrivacyFromDB();
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('ru-RU', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    }).replace(/\//g, '.');
  };

  const schemaMarkup = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "name": "Политика конфиденциальности ДляДетей.ру",
    "description": "Политика конфиденциальности сервиса ДляДетей.ру",
    "url": "https://ddetey.ru/privacy-policy"
  };

  if (loading) return <div>Загружаю...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div>
      <SEO
        title="Политика конфиденциальности ДляДетей.ру"
        description="Ознакомьтесь с политикой конфиденциальности сервиса ДляДетей.ру"
        keywords={['политика конфиденциальности', 'защита данных', 'ДляДетей.ру']}
        image={"/general_images/для-детей-ру-главное-изображение.png"}
        url="https://ddetey.ru/privacy-policy"
        ogType="website"
        schemaMarkup={schemaMarkup}
        canonical={`https://ddetey.ru${location.pathname}`}
      />
      <Header />
      <div className="privacy-policy-container">
        <h1 className="pp-title">{privacyData.title}</h1>
        <p className="pp-last-updated">Последнее обновление: {formatDate(privacyData.lastUpdated)}</p>
        <div className="pp-content" dangerouslySetInnerHTML={{ __html: privacyData.content }}></div>
      </div>
      <Footer />
    </div>
  );
};

export default PrivacyPolicyPage;