import React from 'react';
import { Link } from 'react-router-dom';
import './footer.css';

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-inner">
        <div className="footer-column">
          <h3>ДляДетей.ру</h3>
          <ul>
            {/* <li>О нас</li> */}
            <li><Link to="/skazki">Сказки для детей</Link></li>
            <li><Link to="/blog">Блог</Link></li>
            {/* <li>Контакты</li> */}
            <li><Link to="/terms-of-service">Правила пользования</Link></li>
            <li><Link to="/privacy-policy">Политика конфиденциальности</Link></li>
          </ul>
        </div>
        <div className="footer-column">
          <h3>Организациям</h3>
          <ul>
            <li><Link to="/join-us">Присоединиться</Link></li>
          </ul>
        </div>
        <div className="footer-column">
          <h3>Категории</h3>
          <ul>
            <li>Единоборства для детей</li>
            <li>Футбол для детей</li>
            <li>Хоккей для детей</li>
            <li>Баскетбол для детей</li>
            <li>Зимний спорт для детей</li>
            <li>Волейбол для детей</li>
            <li>Гимнастика для детей</li>
            <li>Легкая атлетика для детей</li>
            <li>Логопед для детей</li>
            <li>Детский центр для детей</li>
            <li>ЕГЭ / ОГЭ для детей</li>
            <li>Школа Языков для детей</li>
            <li>Музыка для детей</li>
            <li>Танцы для детей</li>
            <li>Навыки для детей</li>
            <li>Плавание для детей</li>
            <li>Мастер-классы для детей</li>
            <li>Развитие интеллекта для детей</li>
          </ul>
        </div>
        <div className="footer-logo">
          <img src="/dd_logo/для-детей-ру-лого-белое.png" alt="Logo" />
          <p>© 2024 ДляДетей.ру | ddetey.ru, dljadetei.ru</p>
        </div>
      </div>  
    </footer>
  );
}

export default Footer;