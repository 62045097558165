import React, { useEffect } from 'react';
import { useNavigate, Outlet, useLocation } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../../firebase/FirebaseConfig';
import { FaChartBar, FaCog, FaMapMarkerAlt, FaSignOutAlt } from 'react-icons/fa';
import { useAuth } from '../../../hooks/useAuth';
import './ActivityProviderPanel.css';

const ActivityProviderPanel = () => {
    const  { user, loading, logout } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (!loading && !user) {
            navigate('/activity-provider/login')
        }
    }, [user, loading, navigate]);

    const handleLogout = async () => {
        try {
            await logout();
            navigate('/activity-provider/login');
        } catch (error) {
            console.error("Logout error:", error);
        }
    };
    const icons = [
        { icon: FaChartBar, label: "Мои Объявления", path: "/activity-provider/dashboard" },
        { icon: FaMapMarkerAlt, label: "Главное", path: "edit-activity" },
        { icon: FaCog, label: "Настройки", path: "/activity-provider/account-settings" },
    ];

    const handleIconClick = async (path) => {
        if (path === "edit-activity") {
            // Fetch provider data to get activityId
            const providerDocRef = doc(db, 'activityProviders', user.uid);
            const providerDoc = await getDoc(providerDocRef);

            if (providerDoc.exists() && providerDoc.data().activityId) {
                navigate(`/activity-provider/${providerDoc.data().activityId}/edit`);
            } else {
                console.log("DEBUG! ---> No activity found for this provider...");
            }
        } else {
        navigate(path);
        }
    };

    const isActive = (path) => {
        // Special case for "edit-activity"
        if (path === "edit-activity") {
            return location.pathname.includes('/edit');
        }
        return location.pathname.startsWith(path);
    }

    if (loading) {
        return <div>Загружаю...</div>;
    }

    if (!user) {
        return null; //THIS prevents (or should prevent) rendering of child routes if the user is not authenticated
    }

    return (
        <div className="activity-provider-panel">
            <div className="app-sidebar">
                <div className="app-profile-icon">
                    <img src='/dd_logo/ДляДетей.ру.png' alt='DD Logo' />
                </div>
                <div className="app-middle-icons">
                    {icons.map((IconItem, index) => (
                        <div
                            key={index}
                            className={`icon-wrapper ${isActive(IconItem.path) ? 'active' : '' }`}
                            onClick={() => handleIconClick(IconItem.path)}
                        >
                            <IconItem.icon className="icon" />
                        </div>
                    ))}
                </div>
                <div className="app-logout-icon">
                    <FaSignOutAlt className="icon" onClick={handleLogout} />
                </div>
            </div>

            <div className="app-main-content-container">
                <div className="app-main-content">
                    <Outlet />
                </div>
            </div>

        </div>
    );
};

export default ActivityProviderPanel;