import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../../firebase/FirebaseConfig';
import Header from '../../global/Header/Header';
import Footer from '../../global/Footer/Footer';
import SEO from '../../global/SEO';
import Breadcrumbs from '../../global/Breadcrumbs/Breadcrumbs';
import './Skazka.css';

const Skazka = () => {
  const { skazkaId } = useParams();
  const [skazka, setSkazka] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const location = useLocation();

const generateBreadcrumbs = () => {
  const items = [{ name: 'Главная', path: '/' }];
  items.push({ name: 'Сказки', path: '/skazki' });
  if (skazka) {
    items.push({ name: skazka.title, path: location.pathname });
  }
  return items;
};

const breadcrumbItems = generateBreadcrumbs();

  useEffect(() => {
    const fetchSkazkaById = async () => {
        if (!skazkaId) {
            setError('Ошибка! ID сказки не найден');
            setLoading(false);
            return;
        }
      try {
        const skazkaDocRef = doc(db, 'fairytales', skazkaId);
        const skazkaDocSnapshot = await getDoc(skazkaDocRef);
        if (skazkaDocSnapshot.exists()) {
          setSkazka({
            id: skazkaDocSnapshot.id,
            ...skazkaDocSnapshot.data()
          });
        } else {
          setError('Ошибка! Сказка не найдена');
        }
      } catch (error) {
        console.error("DEBUG: ---> Error fetching skazka by id:", error);
        setError('Ошибка! Невозможно загрузить сказку');
      } finally {
        setLoading(false);
      }
    };
    fetchSkazkaById();
  }, [skazkaId]);

//   const handleBack = () => {
//     navigate('/skazki');
//     setTimeout(() => {
//       window.scrollTo(0, scrollPosition);
//     }, 0);
//   };

  if (loading) return <div>Загружаю...</div>;
  if (error) return <div>{error}</div>;
  if (!skazka) return <div>Сказка с таким ID не найдена...</div>;

  const schemaMarkup = {
    "@context": "https://schema.org",
    "@type": "Article",
    "headline": skazka.title,
    "image": skazka.image,
    "author": "ДляДетей.ру",
    "publisher": {
      "@type": "Organization",
      "name": "ДляДетей.ру",
      "logo": {
        "@type": "ImageObject",
        "url": "https://ddetey.ru/logo.png"
      }
    },
    "datePublished": skazka.date, // If available
    "description": skazka.annotation,
    "breadcrumb": {
      "@type": "BreadcrumbList",
      "itemListElement": breadcrumbItems.map((item, index) => ({
        "@type": "ListItem",
        "position": index + 1,
        "name": item.name,
        "item": `https://ddetey.ru${item.path}`
      }))
    }
  };

  return (
    <div>
      <SEO
        title={skazka.title}
        description={skazka.description}
        keywords={[skazka.title, 'сказки', 'сказка про', 'народные сказки', 'слушать сказку', 'сказка царевна', 'русские сказки', 'читаем сказки', 'сказки читать', 'сказки для детей', 'сказка 3', 'русские народные сказки', 'сказки на ночь', 'детские для детей', 'слушать для детей', 'сказки на ночь для детей', 'аудиосказки для детей', 'развивающие для детей', 'сказки для детей слушать', 'детские сказки', 'аудиосказки', 'развитие детей']}
        image={skazka.image}
        url={`https://ddetey.ru/skazki/${skazkaId}`}
        ogType="article"
        schemaMarkup={schemaMarkup}
        canonical={`https://ddetey.ru${location.pathname}`}
      />
      <Header />
      <Breadcrumbs items={breadcrumbItems} />

      <div className='skazka-id-container'>
        <div>
          <div className='sic-image-and-title'>
            <img src={skazka.image} alt={skazka.title} className='sic-image' />
            <h2 className='sic-title'>{skazka.title}</h2>
          </div>
          <div
            className='sic-content'
            dangerouslySetInnerHTML={{ __html: skazka.content }}
          />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Skazka;