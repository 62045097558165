import React, { useState } from 'react';
import { FaMapMarkerAlt } from 'react-icons/fa';
import './OurLocations.css';

const OurLocations = ({ locations }) => {
    const [activeTab, setActiveTab] = useState(0);

    return (
        <div className="app-our-locations">
          <div className="app-ol-tabs">
            {locations.map((location, index) => (
              <button
                key={index}
                className={`app-ol-tab ${activeTab === index ? 'active' : ''}`}
                onClick={() => setActiveTab(index)}
              >
                {location.city} ({location.addresses.length})
              </button>
            ))}
          </div>
          <div className="app-ol-tab-content">
            {locations[activeTab] && (
              <ul className="app-ol-address-list">
                {locations[activeTab].addresses.map((address, index) => (
                  <li key={index} className="app-ol-address-item">
                    <p><FaMapMarkerAlt className="contact-icon" />{address.address}</p>
                    {address.additionalInfo && (
                      <p className="app-ol-additional-info">{address.additionalInfo}</p>
                    )}
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
      );
    };
    
    export default OurLocations;